import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import api from "../../../components/adminAxios";
import { Link, useNavigate } from "react-router-dom";
import { IconArrowLeft, IconLoader } from "@tabler/icons-react";
import { ADMIN_URL_PATH } from "../../../App";


function TeacherAdd() {
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const [roles, setRoles] = useState([]);
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teacherRoleId, setTeacherRoleId] = useState(null);


  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: "1px solid #dee2e6",
  //     borderColor: state.isFocused
  //       ? "#2684FF"
  //       : errors[state.selectProps.name]
  //         ? "#ff000080" 
  //         : "#cccccc",
  //     "&:hover": {
  //       borderColor: state.isFocused
  //         ? "#2684FF"
  //         : errors[state.selectProps.name]
  //           ? "#ff000080" 
  //           : "#cccccc",
  //     },
  //     boxShadow: errors[state.selectProps.name]
  //       ? "0 0 0 1px red"
  //       : provided.boxShadow,
  //   }),
  // };

  useEffect(() => {
    fetchRoles();
    fetchBatches()
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await api.get("https://elearningbackend.prosevo.com/api/new_role");
      const filteredRoles = response.data.data.map((role) => ({
        value: role.M04_RoleId,
        label: role.M04_RoleName,
      }));

      setRoles(filteredRoles);

      // Find the "teacher" role ID
      const teacherRole = filteredRoles.find((role) => role.label.toLowerCase() === "teacher");

      if (teacherRole) {
        setTeacherRoleId(teacherRole.value); // Store teacher role ID
        setValue("M05_M04_Role", teacherRole.value); // Set the default value as "teacher"
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const fetchBatches = async () => {
    try {
      const response = await api.get('batch_ids');
      const batchData = response.data.data;
      const formattedBatches = Object.entries(batchData).map(([label, value]) => ({
        value: value,
        label: label
      }));
      setBatches(formattedBatches);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  // Function to generate a random password
  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return password;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
  
    // Ensure role is appended as an array, only M05_M04_Role[] is sent
    formData.append("M05_User_Type", "teacher");
    formData.append("M05_M04_Role[]", teacherRoleId); // Send role as an array
    
    // Set default values for M05_AllowLogin and M05_IsActive if not provided
    const allowLogin = data.M05_AllowLogin !== undefined ? data.M05_AllowLogin : 1;
    const isActive = data.M05_IsActive !== undefined ? data.M05_IsActive : 1;
  
    formData.append("M05_AllowLogin", allowLogin ? 1 : 0); // Convert to 1 or 0
    formData.append("M05_IsActive", isActive ? 1 : 0);     // Convert to 1 or 0
    
    // Loop through the form data
    Object.keys(data).forEach((key) => {
      if (key === "M05_M02_BachId") {
        formData.append(key, data[key]); // Send the batch ID correctly
      } else if (key !== "M05_AllowLogin" && key !== "M05_IsActive" && key !== "M05_M04_Role") {  
        // Skip M05_M04_Role, M05_AllowLogin, and M05_IsActive since they are handled separately
        formData.append(key, data[key]);
      }
    });
  
    try {
      const response = await api.post("/users", formData);
      navigate(`/${ADMIN_URL_PATH}/teacher`);
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      if (error.response && error.response.status === 422 && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        Object.keys(validationErrors).forEach((field) => {
          setError(field, {
            type: "server",
            message: validationErrors[field].join(", "),
          });
        });
      } else {
        console.error("Error submitting form:", error);
        alert(error.response.data.errors || "Error adding form");
      }
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div>
      <div className="bg-[#eba21a1f]  w-full min-h-screen flex justify-center items-center p-8 pt-0 overflow-auto relative">
        <div className=" w-ful absolute left-5 top-5">
          <Link to={`/${ADMIN_URL_PATH}/teacher`} className=" block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"><IconArrowLeft /></Link>

        </div>
        <div className="w-full max-w-4xl ">
          <h1 className="text-3xl font-semibold text-black mb-10">
            Teacher Creation
          </h1>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
          >
            <Controller
              name="M05_UserName"
              control={control}
              rules={{ required: "User Name is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_UserName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Teacher Name
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M05_UserName"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="Teacher name"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_UserCode"
              control={control}
              rules={{ required: "User Code is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_UserCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Teacher Code
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M05_UserCode"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="##001"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_CountryName"
              control={control}
              rules={{ required: "Country Name is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_CountryName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country Name
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M05_CountryName"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="India"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_Gender"
              control={control}
              rules={{ required: "Gender is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_Gender"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Gender
                  </label>
                  <select
                    {...field}
                    id="M05_Gender"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_DOB"
              control={control}
              rules={{ required: "Date of Birth is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_DOB"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date of Birth
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="date"
                    id="M05_DOB"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />



            {/* Batch Selection */}
            <Controller
              name="M05_M02_BachId"
              control={control}
              rules={{ required: "Batch ID is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_M02_BachId"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Batch
                  </label>
                  <select
                    {...field}
                    id="M05_M02_BachId"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    <option value="">Select Batch</option>
                    {batches.map((batch) => (
                      <option key={batch.value} value={batch.value}>
                        {batch.label}
                      </option>
                    ))}
                  </select>
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_M04_Role"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label htmlFor="M05_M04_Role" className="block w-full">
                    Role
                  </label>
                  <select
                    {...field}
                    className={`block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${error ? "border-red-500" : ""
                      }`}
                    disabled // Disable the dropdown to prevent changes
                  >
                    {roles.map((role) => (
                      <option key={role.value} value={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </select>
                  {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                </div>
              )}
            />


            <Controller
              name="M05_Qualification"
              control={control}
              rules={{ required: "Qualification is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_Qualification"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Qualification
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M05_Qualification"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="Plus Two"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M06_Specialization"
              control={control}
              rules={{ required: "Specialization is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M06_Specialization"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Specialization
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M06_Specialization"
                    placeholder="specialize"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />


            <Controller
              name="M05_JoiningDate"
              control={control}
              rules={{ required: "Joining Date is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_JoiningDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Joining Date
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="date"
                    id="M05_JoiningDate"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />


            <Controller
              name="M05_MobileNumber"
              control={control}
              rules={{
                required: "Mobile Number is required",
                pattern: {
                  value: /^\+?[1-9]\d{1,14}$/,
                  message: "Invalid phone number",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_MobileNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile Number
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="tel"
                    id="M05_MobileNumber"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="+91 1234567890"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_Address"

              control={control}
              rules={{ required: "Address is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_Address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <textarea
                    {...field}
                    disabled={loading}
                    id="M05_Address"
                    rows="1"
                    placeholder="address"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  ></textarea>
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_Email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_Email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="email"
                    id="M05_Email"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="teacher@gmail.com"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_Password"
              control={control}
              rules={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-2 mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_Password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    App Password
                  </label>
                  <div className="flex items-center space-x-2">
                    <input
                      {...field}
                      value={field.value || ""}
                      readOnly // Disable manual password entry
                      type="text" // Show the password as text
                      id="M05_Password"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                      placeholder="Auto-generated password"
                    />
                    <button
                      type="button"
                      onClick={() => field.onChange(generatePassword())} // Generate and set the password
                      className="px-4 py-2 bg-blue-500 text-white rounded-md"
                    >
                      Generate
                    </button>
                  </div>
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* <Controller
              name="M05_AllowLogin"
              control={control}
              render={({ field }) => (
                <div className="flex items-center mb-4 sm:mb-0">
                  <input
                    {...field}
                    disabled={loading}
                    type="checkbox"
                    id="M05_AllowLogin"
                    className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                  />
                  <label
                    htmlFor="M05_AllowLogin"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Allow Login
                  </label>
                </div>
              )}
            />

            <Controller
              name="M05_IsActive"
              control={control}
              render={({ field }) => (
                <div className="flex items-center mb-4 sm:mb-0">
                  <input
                    {...field}
                    disabled={loading}
                    type="checkbox"
                    id="M05_IsActive"
                    className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                  />
                  <label
                    htmlFor="M05_IsActive"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Is Active
                  </label>
                </div>
              )}
            /> */}

            <div className="col-span-full">
              <button
                disabled={loading}
                type="submit"
                className="w-full py-2 px-4 flex gap-2 justify-center bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                {loading && <IconLoader className="animate-spin" />}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}


export default TeacherAdd
