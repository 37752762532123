import React, { useState } from 'react';
import api from '../../components/adminAxios';
import { ADMIN_URL_PATH } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';

function CoursePage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    M01_Title: '', 
    M01_Description: ''
  });
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
    setServerError('');
  };

  const formatErrorKey = (key) => {
    // Remove the "M01_" prefix from the error key
    return key.replace('m01_', '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!formData.M01_Title.trim()) {
      formErrors.Title = 'Title is required';
    }
    if (!formData.M01_Description.trim()) {
      formErrors.Description = 'Description is required';
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await api.post('/courses', formData);
        if (response.data.success === true) {
          navigate(`/${ADMIN_URL_PATH}/course`);
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const { message, errors } = error.response.data;
          
          // Reformat errors to remove "M01_" prefix
          const formattedErrors = {};
          for (const key in errors) {
            formattedErrors[formatErrorKey(key)] = errors[key][0];
          }

          setServerError(message); // Set the server error message
          setErrors(formattedErrors); // Set validation errors without "M01_" prefix
          alert(message); // Display alert with the error message
        } else {
          console.log('Error creating course:', error);
        }
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div>
      <div 
        className='bg-[#eba21a1f] w-full min-h-screen flex justify-center items-center p-8 overflow-auto relative'
      >
         <div className="w-full absolute left-5 top-5">
          <Link
            to={`/${ADMIN_URL_PATH}/course`}
            className="block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"
          >
            <IconArrowLeft />
          </Link>
        </div>
        <div className="form-container w-full max-w-4xl">
          <h1 className="text-3xl font-semibold text-black mb-10 text-center">Course Creation</h1>
          <form onSubmit={handleSubmit} className="space-y-5">
            <div className="flex flex-col sm:flex-row sm:space-x-5 space-y-5 sm:space-y-0">
              <div className="flex-1">
                <label className='block text-sm font-medium text-gray-700'>Title</label>
                <input
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500'
                  type="text"
                  name="M01_Title"
                  placeholder='Higher Secondary'
                  value={formData.M01_Title}
                  onChange={handleChange}
                />
                {errors.Title && <span className="error-text text-red-600">{errors.Title}</span>}
              </div>

              <div className="flex-1">
                <label className='block text-sm font-medium text-gray-700'>Description</label>
                <input
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500'
                  type="text"
                  name="M01_Description"
                  placeholder='Description'
                  value={formData.M01_Description}
                  onChange={handleChange}
                />
                {errors.Description && <span className="error-text text-red-600">{errors.Description}</span>}
              </div>
            </div>

            {serverError && <div className="text-red-600 text-center">{serverError}</div>}

            <div className="mt-5">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-pumpkinToast-500 text-white rounded-md shadow-sm hover:bg-pumpkinToast-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pumpkinToast-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CoursePage;
