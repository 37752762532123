import React from 'react'
import { OffcanvasComponent } from '../../components/offcanvas'
import { StudentPage } from './students/StudentPage'

export default function Adminpanel() {
  return (
    <div className='bg-[#eba21a1f] min-h-screen w-screen'>

      
        <StudentPage />
 

    </div>
  )
}
