import React, { useEffect, useState } from 'react';
import api from '../../../components/adminAxios';
import { OffcanvasComponent } from '../../../components/offcanvas';
import { ADMIN_URL_PATH } from '../../../App';
// import { OffcanvasComponent } from '../../../components/offcanvas';
import { Link, useNavigate } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';

function Batch() {

  const navigate = useNavigate();
  
  const [batchNames, setBatchNames] = useState([]);
  const [batchIdMap, setBatchIdMap] = useState({});
  const [formData, setFormData] = useState({
    M02_M01_CourseId: '', 
    M02_Name: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "M02_M01_CourseId") {
      const batchId = batchIdMap[value];
      setFormData(prevState => ({
        ...prevState,
        M02_M01_CourseId: batchId
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }

    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const fetchId = async () => {
    try {
      const response = await api.get("/course_ids");
      setBatchIdMap(response.data.data);
      setBatchNames(Object.keys(response.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchId();
  }, []); 

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!formData.M02_M01_CourseId) {
      formErrors.M02_M01_CourseId = 'Course is required';
    }
    if (!formData.M02_Name.trim()) {
      formErrors.M02_Name = 'Description is required';
    }
    if (Object.keys(formErrors).length === 0) {
      handleOnSubmit();
    } else {
      setErrors(formErrors);
    }
  };

  const handleOnSubmit = async () => {
    try {
      const response = await api.post('/batches', formData);
      navigate(`/${ADMIN_URL_PATH}/batch`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-[#eba21a1f] w-full min-h-screen flex justify-center items-center p-8 overflow-auto relative">
      <div className="w-full absolute left-5 top-5">
          <Link
            to={`/${ADMIN_URL_PATH}/batch`}
            className="block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"
          >
            <IconArrowLeft />
          </Link>
        </div>
      <div className="form-container w-full max-w-4xl">
        <h1 className="text-3xl font-semibold text-black mb-10">Batch Creation</h1>
        <form onSubmit={handleSubmit}>

          {/* Stack on mobile, align side-by-side on larger screens */}
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">Batch</label>
              <input
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                type="text"
                name="M02_Name"
                placeholder='10th Batch'
                value={formData.M02_Name}
                onChange={handleChange}
              />
              {errors.M02_Name && <span className="error-text text-red-600">{errors.M02_Name}</span>}
            </div>

            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">Course</label>
              <select
                name="M02_M01_CourseId"
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
              >
                <option value="">Select Course</option>
                {batchNames.map(name => (
                  <option value={name} key={name}>{name}</option>
                ))}
              </select>
              {errors.M02_M01_CourseId && <span className="error-text text-red-600">{errors.M02_M01_CourseId}</span>}
            </div>
          </div>

          <div className="col-span-full mt-5">
            <button
              type="submit"
              className="w-full py-2 px-4 bg-pumpkinToast-500 text-white rounded-md shadow-sm hover:bg-pumpkinToast-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pumpkinToast-500"
            >
              Submit
            </button>
          </div>

        </form>
      </div>
    </div>
  );
}

export default Batch;
