import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLoader } from '@tabler/icons-react';
import api from '../../../components/adminAxios';
import { ADMIN_URL_PATH } from '../../../App';

function StudentDetail({ id, onClose, onDelete }) {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const fetchStudentData = useCallback(async () => {
        try {
            const response = await api.get(`/students/${id}`);
            setUser(response.data.data);
        } catch (error) {
            console.error('Error fetching student data:', error);
            alert('Failed to load student details');
        }
    }, [id]);

    useEffect(() => {
        fetchStudentData();
    }, [fetchStudentData]);

    const handleDeleteModalToggle = () => setIsDeleteModalOpen(!isDeleteModalOpen);

    const handleDeleteStudent = async () => {
        setIsDeleteLoading(true);
        try {
            const response = await api.delete(`/students/${id}`);
            if (response.data.success) {
                onDelete(id);
                onClose();
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error('Error deleting student:', error);
            alert(error.response?.data?.message || 'Failed to delete student');
        } finally {
            setIsDeleteLoading(false);
        }
    };

    const handleEdit = () => {
        navigate(`/${ADMIN_URL_PATH}/student_edit/${id}`);
    };

    if (!user) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-800 bg-opacity-50">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
                <div className="flex items-center justify-between p-4 border-b">
                    <h3 className="text-xl font-semibold text-gray-900">Student Details</h3>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-500"
                    >
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">
                            {user.M05_UserName}'s Details
                        </h2>
                        <button onClick={handleEdit} className="text-blue-600 hover:text-blue-800">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </button>
                    </div>

                    {[
                        { label: "Name", value: user.M05_UserName },
                        { label: "Admission Code", value: user.M05_UserCode },
                        { label: "Country", value: user.M05_CountryName },
                        { label: "Batch", value: user?.batch?.M02_Name || 'N/A' },
                        { label: "Date of Birth", value: user.M05_DOB },
                        { label: "Gender", value: user.M05_Gender },
                        { label: "Qualification", value: user.M05_Qualification || 'N/A' },
                        { label: "Address", value: user.M05_Address },
                        { label: "Phone Number", value: user.M05_MobileNumber },
                        { label: "Email", value: user.M05_Email },
                    ].map((item, index) => (
                        <div key={index} className="flex justify-between items-center py-2 border-b">
                            <p className="font-medium text-gray-600">{item.label}:</p>
                            <p className="text-gray-900">{item.value}</p>
                        </div>
                    ))}

                    <div className="mt-6 flex justify-center">
                        <button
                            onClick={handleDeleteModalToggle}
                            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-150"
                        >
                            Delete Student
                        </button>
                    </div>
                </div>
            </div>

            {isDeleteModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 max-w-sm w-full">
                        <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
                        <p className="mb-4">Are you sure you want to delete this student?</p>
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={handleDeleteModalToggle}
                                disabled={isDeleteLoading}
                                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDeleteStudent}
                                disabled={isDeleteLoading}
                                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 flex items-center"
                            >
                                {isDeleteLoading && <IconLoader className="animate-spin mr-2" />}
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default StudentDetail;