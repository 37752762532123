import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLoader, IconPlus, IconChevronLeft, IconChevronRight, IconSearch, IconEdit, IconTrash } from '@tabler/icons-react';
import api from '../../../components/adminAxios';
import { debounce } from 'lodash';
import FilterButton from '../students/filterbutton';
import { ADMIN_URL_PATH } from '../../../App';

function ClassView() {
  const navigate = useNavigate();
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 0,
  });

  // State for filters
  const [batches, setBatches] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState('');
  const [selectedDivisionId, setSelectedDivisionId] = useState('');

  const fetchClasses = useCallback(async (page = 1, search = '', batchId = '', divisionId = '') => {
    setIsLoading(true);
    try {
      const response = await api.get('/classes', {
        params: { page, search, batch_id: batchId, division_id: divisionId },
      });
      setClasses(response.data.data.data);
      setPagination({
        current_page: response.data.data.current_page,
        last_page: response.data.data.last_page,
        per_page: response.data.data.per_page,
        total: response.data.data.total,
      });
    } catch (error) {
      console.error('Error loading classes:', error);
      alert(error.response?.data?.message || 'Error loading data');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchBatches = async () => {
    try {
      const response = await api.get('https://elearningbackend.prosevo.com/api/batches');
      setBatches(response.data.data.data);
    } catch (error) {
      console.error('Error loading batches:', error);
    }
  };

  const fetchDivisions = async (batchId) => {
    if (!batchId) {
      setDivisions([]);
      return;
    }
    try {
      const response = await api.get(`https://elearningbackend.prosevo.com/api/division?batch_id=${batchId}`);
      setDivisions(response.data.data.data);
    } catch (error) {
      console.error('Error loading divisions:', error);
    }
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  useEffect(() => {
    if (selectedBatchId) {
      fetchDivisions(selectedBatchId);
    } else {
      setDivisions([]);
      setSelectedDivisionId('');
    }
  }, [selectedBatchId]);

  const debouncedFetchClasses = useCallback(
    debounce((search, batchId, divisionId) => fetchClasses(1, search, batchId, divisionId), 300),
    [fetchClasses]
  );

  useEffect(() => {
    debouncedFetchClasses(searchTerm, selectedBatchId, selectedDivisionId);
  }, [debouncedFetchClasses, searchTerm, selectedBatchId, selectedDivisionId]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (filterId, value) => {
    if (filterId === 'batch') {
      setSelectedBatchId(value);
      setSelectedDivisionId('');
    } else if (filterId === 'division') {
      setSelectedDivisionId(value);
    }
  };

  const handleDeleteModalToggle = (classItem) => {
    setSelectedClass(classItem);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleDelete = async () => {
    if (!selectedClass) return;
    setIsDeleteLoading(true);
    try {
      await api.delete(`classes/${selectedClass.M03_ClassesId}`);
      setIsDeleteModalOpen(false);
      alert(`Class "${selectedClass.M03_Title}" has been deleted successfully.`);
      fetchClasses(pagination.current_page, searchTerm, selectedBatchId, selectedDivisionId);
    } catch (error) {
      console.error('Error deleting class:', error);
      alert('Error deleting class. Please try again.');
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.last_page) {
      fetchClasses(newPage, searchTerm, selectedBatchId, selectedDivisionId);
    }
  };

  const filters = [
    {
      id: 'batch',
      label: 'Batch',
      value: selectedBatchId,
      options: batches.map(batch => ({ value: batch.M02_BatchId, label: batch.M02_Name })),
      placeholder: 'All Batches',
    },
    {
      id: 'division',
      label: 'Division',
      value: selectedDivisionId,
      options: divisions.map(division => ({ value: division.M11_Division_id, label: division.M11_Division_name })),
      placeholder: 'All Divisions',
      disabled: !selectedBatchId,
    },
  ];

  return (
    <div className="min-h-screen p-6 bg-gray-100">
      <div className="flex justify-between gap-5 sm:mb-6 mb-3 flex-col-reverse sm:flex-row">
        <div className="relative">
          <input
            type="text"
            placeholder="Search classes..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="pl-10 w-full sm:w-auto pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pumpkinToast-500"
          />
          <IconSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        <div className="flex sm:gap-5 justify-between sm:justify-normal">
          <button
            onClick={() => navigate(`/${ADMIN_URL_PATH}/class_add`)}
            className="btn md:text-base sm:text-sm text-xs bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white px-4 py-2 rounded flex items-center"
          >
            <IconPlus className="sm:me-2 me-1" />
            ADD CLASS
          </button>
          <FilterButton
            filters={filters}
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <div className="max-h-[calc(100vh-220px)] overflow-y-auto">
            <table className="w-full text-sm text-left">
              <thead className="text-xs text-white uppercase bg-pumpkinToast-500 sticky top-0">
                <tr>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">#</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Subject</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Title</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Batch</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Division</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Scheduled At</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Expire At</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">YouTube Link</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="8" className="px-6 py-4 text-center">
                      <IconLoader className="animate-spin mx-auto text-pumpkinToast-500" />
                    </td>
                  </tr>
                ) : classes.length > 0 ? (
                  classes.map((classItem, index) => (
                    <tr
                      key={classItem.M03_ClassesId}
                      className="bg-[#fbcf9c54] border-b border-[#f8c78036] text-black hover:bg-[#fbcf9c8c] transition duration-150 ease-in-out"
                    >
                      <td className="px-6 py-2 md:py-4 font-medium text-nowrap">
                        {(pagination.current_page - 1) * pagination.per_page + index + 1}
                      </td>
                      <td className="px-6 py-2 md:py-4">{classItem.M03_SubjectName}</td>
                      <td className="px-6 py-2 md:py-4">{classItem.M03_Title}</td>
                      <td className="px-6 py-2 md:py-4">{classItem.batch.M02_Name}</td>
                      <td className="px-6 py-2 md:py-4">
                        {classItem.divisions && classItem.divisions.length > 0 ? (
                          <div className="flex flex-col">
                            {classItem.divisions.map((division, idx) => (
                              <span key={idx} className="mb-1">
                                {division.M11_Division_name}
                              </span>
                            ))}
                          </div>
                        ) : (
                          'N/A'
                        )}
                      </td>
                      <td className="px-6 py-2 md:py-4">{classItem.M03_ScheduledAt}</td>
                      <td className="px-6 py-2 md:py-4">{classItem.M03_ExpiryTime}</td>
                      <td className="px-6 py-2 md:py-4">{classItem.M03_YouTubeLink}</td>
                      <td className="px-6 py-2 md:py-4">
                        <button
                          onClick={() => navigate(`/${ADMIN_URL_PATH}/class_edit/${classItem.M03_ClassesId}`)}
                          className="text-blue-600 hover:text-blue-800 mr-2"
                        >
                          <IconEdit size={20} />
                        </button>
                        <button
                          onClick={() => handleDeleteModalToggle(classItem)}
                          className="text-red-600 hover:text-red-800"
                        >
                          <IconTrash size={20} />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center font-bold text-red-600">
                      NO CLASSES FOUND
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Pagination */}
      {pagination && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => handlePageChange(pagination.current_page - 1)}
            disabled={pagination.current_page === 1}
            className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-l-md disabled:bg-gray-300"
          >
            <IconChevronLeft size={20} />
          </button>
          <span className="px-4 py-2 bg-white">
            Page {pagination.current_page} of {pagination.last_page}
          </span>
          <button
            onClick={() => handlePageChange(pagination.current_page + 1)}
            disabled={pagination.current_page === pagination.last_page}
            className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-r-md disabled:bg-gray-300"
          >
            <IconChevronRight size={20} />
          </button>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Confirm Deletion</h3>
            <p className="text-gray-700 mb-6">
              Are you sure you want to delete the class "{selectedClass?.M03_Title}"?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                disabled={isDeleteLoading}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                disabled={isDeleteLoading}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300 flex items-center"
              >
                {isDeleteLoading && <IconLoader className="animate-spin mr-2" size={20} />}
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClassView;