import React, { useEffect, useState } from "react";
import api from "../../../components/adminAxios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { IconArrowLeft, IconLoader } from "@tabler/icons-react";
import { ADMIN_URL_PATH } from "../../../App";

export function DivisionEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [batches, setBatches] = useState([]);
  const [mentors, setMentors] = useState([]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      M11_Batch_id: '',
      M11_Division_name: '',
      M11_M05_User_Id: '',
    },
  });

  useEffect(() => {
    fetchBatches();
    fetchTeachers();
    getStudent();
  }, []);

  const fetchTeachers = async () => {
    try {
      const response = await api.get("https://elearningbackend.prosevo.com/api/teachers");
      const filteredTeachers = response.data.data.data.map((teacher) => ({
        id: teacher.M05_UserId,
        name: teacher.M05_UserName,
      }));
      setMentors(filteredTeachers);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  const fetchBatches = async () => {
    try {
      const response = await api.get('batch_ids');
      const batchData = response.data.data;
      const formattedBatches = Object.entries(batchData).map(([label, value]) => ({
        value: value,
        label: label,
      }));
      setBatches(formattedBatches);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const getStudent = async () => {
    try {
      const response = await api.get(`/division/${id}`);
      const studentData = response.data.data;
      reset({
        M11_Batch_id: studentData.M11_Batch_id || '',
        M11_Division_name: studentData.M11_Division_name || '',
        M11_M05_User_Id: studentData.M11_M05_User_Id || '',
      });
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    try {
      const response = await api.put(`/division/${id}`, formData);
      navigate(`/${ADMIN_URL_PATH}/division`);
    } catch (error) {
      if (error.response && error.response.status === 422 && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        Object.keys(validationErrors).forEach((field) => {
          setError(field, {
            type: "server",
            message: validationErrors[field].join(", "),
          });
        });
      } else {
        console.error("Error submitting form:", error);
        alert(error.response.data.errors || 'Error Updating student');
      }
    }
    setLoading(false);
  };

  return (
    <div className="bg-[#eba21a1f] w-full min-h-screen flex justify-center items-center p-8 overflow-auto relative">
      <div className="w-full absolute left-5 top-5">
        <Link
          to={`/${ADMIN_URL_PATH}/division`}
          className="block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"
        >
          <IconArrowLeft />
        </Link>
      </div>
      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-semibold text-black mb-10">Edit Division Detail's</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="grid md:grid-cols-2 gap-4">

          <Controller
            name="M11_Division_name"
            control={control}
            rules={{ required: "Division Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label htmlFor="M11_Division_name" className="block text-sm font-medium text-gray-700">Division Name</label>
                <input {...field} disabled={loading} type="text" id="M11_Division_name" className="mt-1 block w-full rounded-md border-pumpkinToast-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500" placeholder="Division A" />
                {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
              </div>
            )}
          />

          <Controller
            name="M11_Batch_id"
            control={control}
            rules={{ required: "Batch ID is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label htmlFor="M11_Batch_id" className="block text-sm font-medium text-gray-700">Batch ID</label>
                <select {...field} id="M11_Batch_id" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500">
                  <option value="">Select BatchId</option>
                  {batches?.map((batch) => (
                    <option key={batch.value} value={batch.value}>{batch.label}</option>
                  ))}
                </select>
                {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
              </div>
            )}
          />

          <Controller
            name="M11_M05_User_Id"
            control={control}
            rules={{ required: "Mentor is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label htmlFor="M11_M05_User_Id" className="block text-sm font-medium text-gray-700">Mentor</label>
                <select {...field} disabled={loading} id="M11_M05_User_Id" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500">
                  <option value="">Select Mentor</option>
                  {mentors.map((mentor) => (
                    <option key={mentor.id} value={mentor.id}>{mentor.name}</option>
                  ))}
                </select>
                {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
              </div>
            )}
          />
          
          <div className="col-span-full">
            <button disabled={loading} type="submit" className="w-full py-2 px-4 flex gap-2 justify-center bg-pumpkinToast-500 text-white rounded-md shadow-sm hover:bg-pumpkinToast-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              {loading && <IconLoader className="animate-spin" />}
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
