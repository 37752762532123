import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLoader, IconPlus, IconChevronLeft, IconChevronRight, IconSearch } from '@tabler/icons-react';
import api from '../../../components/adminAxios';
import DivisionDetails from './DivisionDetails';
import FilterButton from '../students/filterbutton';
import { debounce } from 'lodash';
import { ADMIN_URL_PATH } from '../../../App';

function DivisionList() {
  const navigate = useNavigate();
  const [divisions, setDivisions] = useState([]);
  const [selectedDivisionId, setSelectedDivisionId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [batches, setBatches] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState('');
  const [selectedMentorId, setSelectedMentorId] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1
  });

  const fetchDivisions = useCallback(async (page = 1, search = '', batch_Id = '', mentorId = '') => {
    setIsLoading(true);
    const token = localStorage.getItem('adminToken');
    try {
      if (!token) {
        throw new Error("NO TOKEN FOUND IN LOCAL STORAGE");
      }
      let url = `/division?page=${page}&search=${search}`;
      if (batch_Id) url += `&batch_id=${batch_Id}`;
      if (mentorId) url += `&mentor_id=${mentorId}`;

      const response = await api.get(url);

      setDivisions(response.data.data.data);
      setPagination({
        total: response.data.data.total,
        per_page: response.data.data.per_page,
        current_page: response.data.data.current_page,
        last_page: response.data.data.last_page,
      });
    } catch (error) {
      console.error('Error loading divisions:', error);
      alert(error.response?.data?.message || 'Error loading data');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedFetchDivisions = useCallback(
    debounce((search, batch_Id, mentorId) => fetchDivisions(1, search, batch_Id, mentorId), 1000),
    [fetchDivisions]
  );

  useEffect(() => {
    debouncedFetchDivisions(searchTerm, selectedBatchId, selectedMentorId);
  }, [debouncedFetchDivisions, searchTerm, selectedBatchId, selectedMentorId]);

  // Fetch all batches
  const fetchBatches = useCallback(async () => {
    try {
      const response = await api.get('/batches');
      setBatches(response.data.data.data);
    } catch (error) {
      console.error('Error loading batches:', error);
    }
  }, []);

  // Fetch all mentors or mentors based on selected batch
  const fetchMentors = useCallback(async (batch_Id = '') => {
    try {
      let url = 'https://elearningbackend.prosevo.com/api/teachers';
      if (batch_Id) url += `?batch_id=${batch_Id}`; // If batch is selected, add it to the URL
      const response = await api.get(url);
      setMentors(response.data.data.data);
    } catch (error) {
      console.error('Error loading mentors:', error);
    }
  }, []);

  // Fetch batches and mentors on component mount
  useEffect(() => {
    fetchBatches();
    fetchMentors(); // Initially fetch all mentors
  }, [fetchBatches, fetchMentors]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDivisionClick = (id) => {
    setSelectedDivisionId(id);
    setIsModalOpen(true);
  };

  const handleDivisionDelete = useCallback((deletedDivisionId) => {
    setDivisions(prevDivisions => prevDivisions.filter(division => division.M11_Division_id !== deletedDivisionId));
    setIsModalOpen(false);
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.last_page) {
      fetchDivisions(newPage, searchTerm, selectedBatchId, selectedMentorId);
    }
  };

  const handleFilterChange = (filterId, value) => {
    if (filterId === 'batch') {
      setSelectedBatchId(value);
      setSelectedMentorId(''); // Reset mentor when batch changes
      fetchMentors(value); // Fetch mentors for the selected batch
    } else if (filterId === 'mentor') {
      setSelectedMentorId(value);
    }
  };

  const filters = [
    {
      id: 'batch',
      label: 'Batch',
      value: selectedBatchId,
      options: batches.map(batch => ({ value: batch.M02_BatchId, label: batch.M02_Name })),
      placeholder: 'All Batches',
    },
    {
      id: 'mentor',
      label: 'Mentor',
      value: selectedMentorId,
      options: mentors.map(mentor => ({ value: mentor.M05_UserId, label: mentor.M05_UserName })),
      placeholder: 'All Mentors',
    },
  ];

  return (
    <div className="min-h-screen p-6 bg-gray-100">
      <div className="flex justify-between gap-5 sm:mb-6 mb-3 flex-col-reverse sm:flex-row">
        <div className="relative">
          <input
            type="text"
            placeholder="Search divisions..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="pl-10 w-full sm:w-auto pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pumpkinToast-500"
          />
          <IconSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        <div className="flex sm:gap-5 justify-between sm:justify-normal">
          <button
            onClick={() => navigate(`/${ADMIN_URL_PATH}/division_add`)}
            className="btn md:text-base sm:text-sm text-xs bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white px-4 py-2 rounded flex items-center"
          >
            <IconPlus className="sm:me-2 me-1" />
            ADD DIVISION
          </button>
          <FilterButton
            filters={filters}
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>

    
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <div className="max-h-[calc(100vh-220px)] overflow-y-auto">
            <table className="w-full text-sm text-left">
              <thead className="text-xs text-white uppercase bg-pumpkinToast-500 sticky top-0">
                <tr>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">#</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Division Name</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Mentor Name</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Batch Name</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="3" className="px-6 py-4 text-center">
                      <IconLoader className="animate-spin mx-auto text-black" />
                    </td>
                  </tr>
                ) : divisions.length > 0 ? (
                  divisions.map((division, index) => (
                    <tr
                      key={division.M11_Division_id}
                      className="bg-[#fbcf9c54] border-b border-[#f8c78036]  text-black cursor-pointer hover:bg-[#fbcf9c8c] transition duration-150 ease-in-out"
                      onClick={() => handleDivisionClick(division.M11_Division_id)}
                    >
                      <td className="px-6 py-2 md:py-4 font-medium text-nowrap">
                                                {(pagination.current_page - 1) * pagination.per_page + index + 1}
                                            </td>
                      <td className="px-6 py-2 md:py-4 font-medium">{division.M11_Division_name}</td>
                      <td className="px-6 py-2 md:py-4">{division.mentor?.M05_UserName}</td>
                      <td className="px-6 py-2 md:py-4">{division.batch.M02_Name}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="px-6 py-4 text-center font-bold text-red-600">
                      NO DIVISIONS FOUND
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Pagination */}
      {pagination && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => handlePageChange(pagination.current_page - 1)}
            disabled={pagination.current_page === 1}
            className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-l-md disabled:bg-gray-300"
          >
            <IconChevronLeft size={20} />
          </button>
          <span className="px-4 py-2 bg-white">
            Page {pagination.current_page} of {pagination.last_page}
          </span>
          <button
            onClick={() => handlePageChange(pagination.current_page + 1)}
            disabled={pagination.current_page === pagination.last_page}
            className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-r-md disabled:bg-gray-300"
          >
            <IconChevronRight size={20} />
          </button>
        </div>
      )}

      {isModalOpen && (
        <DivisionDetails
          id={selectedDivisionId}
          onClose={() => setIsModalOpen(false)}
          onDelete={handleDivisionDelete}
        />
      )}
    </div>
  );
}

export default DivisionList;
