import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_URL_PATH } from '../../App';
import { IconLoader, IconPlus, IconChevronLeft, IconChevronRight, IconSearch, IconEdit, IconTrash } from '@tabler/icons-react';
import api from '../../components/adminAxios';
import { debounce } from 'lodash';

function CourseView() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1
  });

  const fetchCourses = useCallback(async (page = 1, search = '') => {
    setLoading(true);
    try {
      const response = await api.get(`/courses?page=${page}&search=${search}`);
      setCourses(response.data.data.data);
      setPagination({
        total: response.data.data.total,
        per_page: response.data.data.per_page,
        current_page: response.data.data.current_page,
        last_page: response.data.data.last_page,
      });
    } catch (error) {
      console.error('Error loading courses:', error);
      alert(error.response?.data?.message || 'Error loading data');
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedFetchCourses = useCallback(
    debounce((search) => fetchCourses(1, search), 1000),
    [fetchCourses]
  );

  useEffect(() => {
    debouncedFetchCourses(searchTerm);
  }, [debouncedFetchCourses, searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOnclick = async (id) => {
    navigate(`/${ADMIN_URL_PATH}/course_edit/${id}`);
  };

  const handleDeleteModalToggle = (course = null) => {
    setSelectedCourse(course);
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleDelete = async () => {
    if (!selectedCourse) return;
    setDeleteLoading(true);
    try {
      await api.delete(`/courses/${selectedCourse.M01_CourseId}`);
      alert(`Course "${selectedCourse.M01_Title}" has been deleted successfully.`);
      setDeleteModalOpen(false);
      fetchCourses(pagination.current_page, searchTerm);
    } catch (error) {
      if (error.response?.status === 400 || error.response?.status === 403) {
        alert(error.response.data.message);
      } else {
        console.log(error);
      }
    } finally {
      setDeleteLoading(false);
      setDeleteModalOpen(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.last_page) {
      fetchCourses(newPage, searchTerm);
    }
  };

  return (
    <div className="min-h-screen p-6">
      <div className="flex flex-col-reverse sm:flex-row justify-between gap-5 sm:mb-6 mb-3">
        <div className="relative">
          <input
            type="text"
            placeholder="Search courses..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="pl-10 pr-4 w-full sm:w-auto py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pumpkinToast-500"
          />
          <IconSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        <button
          
          onClick={() => navigate(`/${ADMIN_URL_PATH}/course_add`)}
          className="btn md:text-base text-sm bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white px-4 py-2 rounded flex items-center"
        >
          <IconPlus className="me-2" />
          ADD COURSE
        </button>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <div className="max-h-[calc(100vh-220px)] overflow-y-auto">
            <table className="w-full text-sm text-left">
              <thead className="text-xs text-white uppercase bg-pumpkinToast-500 sticky top-0">
                <tr>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">#</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">TITLE</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">DESCRIPTION</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="3" className="px-6 py-4 text-center">
                      <IconLoader className="animate-spin mx-auto" />
                    </td>
                  </tr>
                ) : courses.length > 0 ? (
                  courses.map((courseItem, index) => (
                    <tr
                      key={courseItem.M01_CourseId}
                      className="bg-[#fbcf9c54] border-b border-[#f8c78036] text-black hover:bg-[#fbcf9c8c] transition duration-150 ease-in-out"
                    >
                      <td className="px-6 py-2 md:py-4 font-medium text-nowrap">
                                                {(pagination.current_page - 1) * pagination.per_page + index + 1}
                                            </td>
                      <td className="px-6 py-2 md:py-4 font-medium text-nowrap">
                        {courseItem.M01_Title}
                      </td>
                      <td className="px-6 py-2 md:py-4">
                        {courseItem.M01_Description}
                      </td>
                      <td className="px-6 py-2 md:py-4">
                        <div className="flex space-x-2">
                          <IconEdit
                            className="cursor-pointer text-blue-600 hover:text-blue-800"
                            onClick={() => handleOnclick(courseItem.M01_CourseId)}
                          />
                          <IconTrash
                            className="cursor-pointer text-red-600 hover:text-red-800"
                            onClick={() => handleDeleteModalToggle(courseItem)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      className="px-6 py-4 text-center font-bold text-red-600"
                    >
                      NO COURSES FOUND
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Pagination */}
      {pagination && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => handlePageChange(pagination.current_page - 1)}
            disabled={pagination.current_page === 1}
            className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-l-md disabled:bg-gray-300"
          >
            <IconChevronLeft size={20} />
          </button>
          <span className="px-4 py-2 bg-white">
            Page {pagination.current_page} of {pagination.last_page}
          </span>
          <button
            onClick={() => handlePageChange(pagination.current_page + 1)}
            disabled={pagination.current_page === pagination.last_page}
            className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-r-md disabled:bg-gray-300"
          >
            <IconChevronRight size={20} />
          </button>
        </div>
      )}

      {isDeleteModalOpen && (
        <div id="popup-modal" className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                disabled={isDeleteLoading}
                onClick={() => handleDeleteModalToggle(null)}
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1l6 6m0 0 6-6M7 7l6 6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-6 text-center">
                <svg
                  aria-hidden="true"
                  className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 12l6-6m0 0l6 6m-6-6v12"
                  />
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to delete the course <span className="font-semibold">{selectedCourse?.M01_Title}</span>?
                </h3>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  {isDeleteLoading ? 'Deleting...' : 'Yes, Delete'}
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteModalToggle(null)}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  No, Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseView;