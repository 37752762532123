import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLoader, IconPlus, IconChevronLeft, IconChevronRight, IconSearch, IconTrash, IconEdit } from '@tabler/icons-react';
import api from '../../../components/adminAxios';
import { debounce } from 'lodash';
import FilterButton from '../students/filterbutton';
import { ADMIN_URL_PATH } from '../../../App';

function BatchList() {
    const navigate = useNavigate();
    const [batches, setBatches] = useState([]);
    const [courses, setCourses] = useState([]); // Store courses for filter
    const [selectedCourse, setSelectedCourse] = useState(''); // Selected course ID
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
    });
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Fetch batches
    const fetchBatches = useCallback(async (page = 1, search = '', course = '') => {
        setIsLoading(true);
        try {
            const response = await api.get(`/batches?page=${page}&search=${search}&course_id=${course}`);
            setBatches(response.data.data.data);
            setPagination({
                total: response.data.data.total,
                per_page: response.data.data.per_page,
                current_page: response.data.data.current_page,
                last_page: response.data.data.last_page,
            });
        } catch (error) {
            console.error('Error loading batches:', error);
            alert(error.response?.data?.message || 'Error loading data');
        } finally {
            setIsLoading(false);
        }
    }, []);

    // Debounced fetch for search term
    const debouncedFetchBatches = useCallback(
        debounce((search, course) => fetchBatches(1, search, course), 1000),
        [fetchBatches]
    );

    // Fetch courses for the filter
    const fetchCourses = useCallback(async () => {
        try {
            const response = await api.get('/courses');
            setCourses(response.data.data.data); // Store courses for dropdown
        } catch (error) {
            console.error('Error loading courses:', error);
            alert('Error loading courses');
        }
    }, []);

    useEffect(() => {
        fetchCourses(); // Fetch courses on component mount
        debouncedFetchBatches(searchTerm, selectedCourse); // Fetch batches when component loads
    }, [debouncedFetchBatches, fetchCourses, searchTerm, selectedCourse]);

    // Handle search input
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Handle filter changes
    const handleFilterChange = (filterId, value) => {
        if (filterId === 'course') {
            setSelectedCourse(value);
        }
    };

    // Handle pagination
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.last_page) {
            fetchBatches(newPage, searchTerm, selectedCourse);
        }
    };

    // Handle edit and delete logic (unchanged)
    const handleEdit = (id) => {
        navigate(`/${ADMIN_URL_PATH}/batch_edit/${id}`);
    };

    const handleDeleteModalToggle = (batch) => {
        setSelectedBatch(batch);
        setIsDeleteModalOpen(!isDeleteModalOpen);
        setErrorMessage('');
    };

    const handleDelete = async () => {
        if (!selectedBatch) return;
        setIsDeleteLoading(true);
        setErrorMessage('');
        try {
            await api.delete(`/batches/${selectedBatch.M02_BatchId}`);
            setIsDeleteModalOpen(false);
            fetchBatches(pagination.current_page, searchTerm, selectedCourse);
        } catch (error) {
            console.error('Error deleting batch:', error);
            if (error.response && error.response.status === 403) {
                setErrorMessage(error.response.data.message || 'Cannot delete this batch.');
            } else {
                setErrorMessage('An error occurred while deleting the batch. Please try again.');
            }
        } finally {
            setIsDeleteLoading(false);
        }
    };

    // Filter options
    const filters = [
        {
            id: 'course',
            label: 'Course',
            value: selectedCourse,
            options: courses.map(course => ({ value: course.M01_CourseId, label: course.M01_Title })),
            placeholder: 'All Courses',
        },
    ];

    return (
        <div className="min-h-screen p-6">
            <div className="flex justify-between flex-col-reverse sm:flex-row gap-5 sm:mb-6 mb-3">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search batches..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="pl-10 pr-4 w-full sm:w-auto py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pumpkinToast-500"
                    />
                    <IconSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
                <div className="flex sm:justify-normal justify-between sm:gap-5 gap-3">
                    <button
                        onClick={() => navigate(`/${ADMIN_URL_PATH}/batch_add`)}
                        className="btn md:text-base text-sm bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white px-4 py-2 rounded flex items-center"
                    >
                        <IconPlus className="me-2" />
                        ADD BATCH
                    </button>
                    {/* Filter Dropdown */}
                    <FilterButton filters={filters} onFilterChange={handleFilterChange} />
                </div>
            </div>

            {/* Existing Batch Table and Pagination */}
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <div className="max-h-[calc(100vh-220px)] overflow-y-auto">
                        <table className="w-full text-sm text-left">
                            {/* Table Head */}
                            <thead className="text-xs text-white uppercase bg-pumpkinToast-500 sticky top-0">
                                <tr>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">#</th>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Batch Name</th>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Course</th>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan="3" className="px-6 py-4 text-center">
                                            <IconLoader className="animate-spin mx-auto text-pumpkinToast-500" />
                                        </td>
                                    </tr>
                                ) : batches.length > 0 ? (
                                    batches.map((batch, index) => (
                                        <tr
                                            key={batch.M02_BatchId}
                                            className="bg-[#fbcf9c54] border-b border-[#f8c78036] text-black hover:bg-[#fbcf9c8c]  transition duration-150 ease-in-out"
                                        >
                                          <td className="px-6 py-2 md:py-4 font-medium text-nowrap">
                                                {(pagination.current_page - 1) * pagination.per_page + index + 1}
                                            </td>
                                            <td className="px-6 py-2 md:py-4 font-medium text-nowrap">{batch.M02_Name}</td>
                                            <td className="px-6 py-2 md:py-4 text-nowrap">{batch.course?.M01_Title || 'No Course Available'}</td>
                                            <td className="px-6 py-2 md:py-4 text-nowrap">
                                                <button
                                                    onClick={() => handleEdit(batch.M02_BatchId)}
                                                    className="text-blue-600 hover:text-blue-800 mr-2"
                                                >
                                                    <IconEdit size={20} />
                                                </button>
                                                <button
                                                    onClick={() => handleDeleteModalToggle(batch)}
                                                    className="text-red-600 hover:text-red-800"
                                                >
                                                    <IconTrash size={20} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="px-6 py-4 text-center font-bold text-red-600">
                                            NO BATCHES FOUND
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Pagination */}
            {pagination && (
                <div className="flex justify-center items-center mt-4">
                    <button
                        onClick={() => handlePageChange(pagination.current_page - 1)}
                        disabled={pagination.current_page === 1}
                        className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-l-md disabled:bg-gray-300"
                    >
                        <IconChevronLeft size={20} />
                    </button>
                    <span className="px-4 py-2 bg-white">
                        Page {pagination.current_page} of {pagination.last_page}
                    </span>
                    <button
                        onClick={() => handlePageChange(pagination.current_page + 1)}
                        disabled={pagination.current_page === pagination.last_page}
                        className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-r-md disabled:bg-gray-300"
                    >
                        <IconChevronRight size={20} />
                    </button>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 max-w-sm w-full">
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Confirm Deletion</h3>
                        <p className="text-gray-700 mb-6">
                            Are you sure you want to delete the batch "{selectedBatch?.M02_Name}"?
                        </p>
                        {errorMessage && (
                            <p className="text-red-600 mb-4">{errorMessage}</p>
                        )}
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={() => setIsDeleteModalOpen(false)}
                                disabled={isDeleteLoading}
                                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition duration-300"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                disabled={isDeleteLoading}
                                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300 flex items-center"
                            >
                                {isDeleteLoading && <IconLoader className="animate-spin mr-2" size={20} />}
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BatchList;