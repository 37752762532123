import React, { useEffect, useState } from "react";
import api from "../../../components/adminAxios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconArrowLeft, IconLoader, IconArrowBack } from "@tabler/icons-react";
import { Controller, useForm } from "react-hook-form";
import { ADMIN_URL_PATH } from "../../../App";

function StudentEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [initialPassword, setInitialPassword] = useState('');
  const [roles, setRoles] = useState([]);
  const [studentData, setStudentData] = useState('');
  const [batchIdMap, setBatchIdMap] = useState({});
  const [studentRoleId, setStudentRoleId] = useState(null);
  const [divisionNames, setDivisionNames] = useState([]);
  const [batchNames, setBatchNames] = useState([]);
  const [divisionMap, setDivisionMap] = useState({});
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      M05_UserName: '',
      M05_UserCode: '',
      M05_CountryName: '',
      M05_DOB: '',
      M05_Gender: '',
      M05_Qualification: '',
      M05_Address: '',
      M05_MobileNumber: '',
      M05_Email: '',
      M05_JoiningDate: '',
      roles: [],
      M05_AllowLogin: false,
      M05_IsActive: false,
      M05_Password: '',
    },
  });

  // Fetch Batches
  const fetchBatches = async () => {
    try {
      const response = await api.get("https://elearningbackend.prosevo.com/api/batch_ids");
      setBatchIdMap(response.data.data);
      setBatchNames(Object.keys(response.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch Division based on selected Batch
  const fetchDivision = async (batchId) => {
    try {
      const response = await api.get(`https://elearningbackend.prosevo.com/api/division?batch_id=${batchId}`);
      const divisions = response.data.data.data;

      if (divisions.length > 0) {
        const divisionMap = divisions.reduce((map, division) => {
          map[division.M11_Division_name] = division.M11_Division_id;
          return map;
        }, {});

        setDivisionMap(divisionMap);
        setDivisionNames(Object.keys(divisionMap));
      } else {
        setDivisionMap({});
        setDivisionNames([]);
      }
    } catch (error) {
      console.error("Error fetching divisions:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
    getStudent();
    fetchBatches();
  }, []);

  const getStudent = async () => {
    try {
      const response = await api.get(`/students/${id}`);
      const studentData = response.data.data;
      setStudentData(studentData);
      setInitialPassword(studentData.M05_Password);
      reset({
        M05_UserName: studentData.M05_UserName || '',
        M05_UserCode: studentData.M05_UserCode || '',
        M05_CountryName: studentData.M05_CountryName || '',
        M05_DOB: studentData.M05_DOB || '',
        M05_Gender: studentData.M05_Gender || '',
        M05_Qualification: studentData.M05_Qualification || '',
        M05_Address: studentData.M05_Address || '',
        M05_MobileNumber: studentData.M05_MobileNumber || '',
        M05_Email: studentData.M05_Email || '',
        M05_JoiningDate: studentData.M05_JoiningDate || '',
        roles: studentData.roles || [],
        M05_AllowLogin: studentData.M05_AllowLogin || false,
        M05_IsActive: studentData.M05_IsActive || false,
        M05_M02_BachId: studentData.M05_M02_BachId || '',
        M07_M11_DivisionId: studentData.student?.M07_M11_DivisionId || '',
        M05_Password: studentData.M05_Password || '',
      });
      // Fetch divisions based on the fetched batch ID
      if (studentData.M05_M02_BachId) {
        fetchDivision(studentData.M05_M02_BachId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await api.get("https://elearningbackend.prosevo.com/api/new_role");
      const filteredRoles = response.data.data.map((role) => ({
        value: role.M04_RoleId,
        label: role.M04_RoleName,
      }));

      setRoles(filteredRoles);
      const studentRole = filteredRoles.find(role => role.label === "student");
      if (studentRole) {
        setStudentRoleId(studentRole.value);
        // Set the role in array format
        setValue("M05_M04_Role", [studentRole.value]); // Wrap in an array
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const generatePassword = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
    let password = '';
    for (let i = 0; i < 12; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setValue('M05_Password', password); // Update the password field with the generated password
    return password;
  };

  const handleResetPassword = () => {
    setValue('M05_Password', initialPassword); // Reset to the initially fetched password
  };


  const onSubmit = async (formData) => {
    setLoading(true);
    try {
        // Create a new object to send as JSON
        const updatedData = {
            ...formData,
            // Set M05_M04_Role in the required format
            "M05_M04_Role[]": 14, // Changed format as requested
            "M05_User_Type": "student"
        };

        // Send the updated data
        const response = await api.put(`/users/${id}`, updatedData);
        navigate(`/${ADMIN_URL_PATH}/adminpanel`);
    } catch (error) {
        if (error.response && error.response.status === 422 && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            Object.keys(validationErrors).forEach((field) => {
                setError(field, {
                    type: "server",
                    message: validationErrors[field].join(", "),
                });
            });
        } else {
            console.error("Error submitting form:", error);
            alert(error.response.data.errors || 'Error Updating student');
        }
    } finally {
        setLoading(false); // Ensure loading is turned off even if there is an error
    }
};


  return (
    <div className="bg-[#eba21a1f] w-full min-h-screen flex justify-center items-center p-8 overflow-auto relative">
      <div className="w-full absolute left-5 top-5">
        <Link to={`/${ADMIN_URL_PATH}/adminpanel`} className=" block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"><IconArrowLeft /></Link>
      </div>
      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-semibold text-black mb-10">
          Edit Student Details
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
        >
          <Controller
            name="M05_UserName"
            control={control}
            rules={{ required: "User Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_UserName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Student Name
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_UserName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Student Name"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_UserCode"
            control={control}
            rules={{ required: "User Code is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_UserCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Student Code
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_UserCode"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="##001"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_CountryName"
            control={control}
            rules={{ required: "Country Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_CountryName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country Name
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_CountryName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="India"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Gender"
            control={control}
            rules={{ required: "Gender is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Gender
                </label>
                <select
                  {...field}
                  id="M05_Gender"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_DOB"
            control={control}
            rules={{ required: "Date of Birth is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_DOB"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date of Birth
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="date"
                  id="M05_DOB"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_M02_BachId"
            control={control}
            rules={{ required: "Batch ID is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label htmlFor="M05_M02_BachId" className="block text-sm font-medium text-gray-700">Batch ID</label>
                <select
                  {...field}
                  id="M05_M02_BachId"
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 ${error ? "border-red-500" : ""}`}
                  onChange={(e) => {
                    const selectedBatchId = e.target.value;
                    field.onChange(selectedBatchId);
                    if (selectedBatchId) {
                      fetchDivision(selectedBatchId);
                    } else {
                      setDivisionNames([]);
                    }
                  }}
                >
                  <option value="">Select Batch</option>
                  {Object.entries(batchIdMap).map(([name, id]) => (
                    <option key={id} value={id}>{name}</option>
                  ))}
                </select>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M07_M11_DivisionId"
            control={control}
            rules={{ required: "Division is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label htmlFor="M07_M11_DivisionId" className="block text-sm font-medium text-gray-700">Division</label>
                <select
                  {...field}
                  id="M07_M11_DivisionId"
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 ${error ? "border-red-500" : ""}`}
                  disabled={Object.keys(divisionMap).length === 0} // Disable if no divisions are available
                >
                  <option value="">Select Division</option>
                  {divisionNames.map((name) => (
                    <option key={divisionMap[name]} value={divisionMap[name]}>{name}</option>
                  ))}
                </select>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_M04_Role"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_M04_Role"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Role
                </label>
                <select
                  {...field}
                  id="M05_M04_Role"
                  className={`block w-full mt-1 rounded-md border-gray-300 shadow-sm sm:text-sm ${error ? "border-red-500" : ""}`}
                  disabled // Disable the dropdown to prevent changes
                >
                  {roles.map(role => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
                {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
              </div>
            )}
          />



          <Controller
            name="M05_Qualification"
            control={control}
            rules={{ required: "Qualification is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Qualification"
                  className="block text-sm font-medium text-gray-700"
                >
                  Qualification
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_Qualification"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Plus Two"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_JoiningDate"
            control={control}
            rules={{ required: "Joining Date is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_JoiningDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Joining Date
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="date"
                  id="M05_JoiningDate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_MobileNumber"
            control={control}
            rules={{
              required: "Mobile Number is required",
              pattern: {
                value: /^\+?[1-9]\d{1,14}$/,
                message: "Invalid phone number",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_MobileNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobile Number
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="tel"
                  id="M05_MobileNumber"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="+91 8979856710"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Email"
            control={control}
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="email"
                  id="M05_Email"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="student@gmail.com"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Address"

            control={control}
            rules={{ required: "Address is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-4">
                <label
                  htmlFor="M05_Address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address
                </label>
                <textarea
                  {...field}
                  disabled={loading}
                  id="M05_Address"
                  rows="1"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Enter your address"
                ></textarea>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Password"
            control={control}
            rules={{
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="col-span-2 mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Password"
                  className="block text-sm font-medium text-gray-700"
                >
                  App Password
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    {...field}
                    readOnly // Disables manual password entry
                    type="text" // Change this to "text" for visibility
                    id="M05_Password"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="Auto-generated password"
                  />
                  <button
                    type="button"
                    onClick={() => field.onChange(generatePassword())} // Set the generated password
                    className="px-4 py-2 bg-blue-500 text-white rounded-md"
                  >
                    Generate
                  </button>
                  <button
                    type="button"
                    onClick={handleResetPassword} // Reset to the initial password
                    className="px-4 py-2 bg-gray-300 text-black rounded-md"
                  >
                    <IconArrowBack stroke={2} />
                  </button>
                </div>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          {/* <Controller
            name="M05_AllowLogin"
            control={control}
            render={({ field }) => (
              <div className="flex items-center mb-4 sm:mb-0">
                <input
                  {...field}
                  disabled={loading}
                  type="checkbox"
                  id="M05_AllowLogin"
                  className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                />
                <label
                  htmlFor="M05_AllowLogin"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Allow Login
                </label>
              </div>
            )}
          /> */}

          {/* <Controller
            name="M05_IsActive"
            control={control}
            render={({ field }) => (
              <div className="flex items-center mb-4 sm:mb-0">
                <input
                  {...field}
                  disabled={loading}
                  type="checkbox"
                  id="M05_IsActive"
                  className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                />
                <label
                  htmlFor="M05_IsActive"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Is Active
                </label>
              </div>
            )}
          /> */}

          <div className="col-span-full">
            <button
              disabled={loading}
              type="submit"
              className="w-full py-2 px-4 flex gap-2 justify-center bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {loading && <IconLoader className="animate-spin" />}
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StudentEdit;