import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLoader } from '@tabler/icons-react';
import api from '../../../components/adminAxios';
import { ADMIN_URL_PATH } from '../../../App';

function TeacherDetails({ id, onClose, onDelete }) {
    const navigate = useNavigate();
    const [teacher, setTeacher] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const getData = async () => {
        try {
            const response = await api.get(`/teachers/${id}`);
            setTeacher(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getData();
    }, [id]);

    const handleDeleteModalToggle = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleDeleteTeacher = async () => {
        setIsDeleteLoading(true);
        if (!teacher) return;
        try {
            const response = await api.delete(`/teachers/${teacher.M05_UserId}`);
            if (response.data.success === true) {
                onDelete(teacher.M05_UserId);
                onClose();
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            alert(error.response?.data?.message || 'Something went wrong');
        } finally {
            setIsDeleteLoading(false);
        }
    }

    const handleEdit = () => {
        if (!teacher) return;
        try {
            navigate(`/${ADMIN_URL_PATH}/teacher_edit/${teacher.M05_UserId}`);
        } catch (error) {
            console.log(error);
        }
    }

    if (!teacher) {
        return <div className="font-sans">Loading...</div>;
    }

    return (
        <div>
            <div className="fixed inset-0 z-50 flex justify-center items-center overflow-y-auto bg-gray-800 bg-opacity-50">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4 mt-18 mb-13" style={{ borderRadius: '8px', maxWidth: '600px', width: '100%' }}>
                    {/* Modal content */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Teacher Details
                        </h3>
                        <button
                            onClick={onClose}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* Teacher details content */}
                    {/* ... (rest of the teacher details content remains the same) ... */}
                    <div className="p-6 rounded-lg shadow-md w-full">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className="text-2xl font-semibold text-gray-800 mb-4">
                                {teacher.M05_UserName}'s Details
                            </p>

                            <svg onClick={() => handleEdit(teacher.M05_UserId)} style={{ color: 'gray', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mb-3 ">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                            </svg>

                        </div>
                        <div className="f">
                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Name:</p>
                                <p className="text-gray-900">{teacher.M05_UserName}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Admission Code:</p>
                                <p className="text-gray-900">{teacher.M05_UserCode}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Country:</p>
                                <p className="text-gray-900">{teacher.M05_CountryName}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Bach ID:</p>
                                <p className="text-gray-900">{teacher?.batch?.M02_Name || "N/A"}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Date of Birth:</p>
                                <p className="text-gray-900">{teacher.M05_DOB}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Gender:</p>
                                <p className="text-gray-900">{teacher.M05_Gender}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Qualification:</p>
                                <p className="text-gray-900">{teacher.M05_Qualification || 'N/A'}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Address:</p>
                                <p className="text-gray-900">{teacher.M05_Address}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Phone Number:</p>
                                <p className="text-gray-900">{teacher.M05_MobileNumber}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Email:</p>
                                <p className="text-gray-900">{teacher.M05_Email}</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <button
                                onClick={handleDeleteModalToggle} // Toggle the delete modal on click
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    // backgroundColor: '#800000',
                                }}
                                type="button"
                                className="text-gray-900 mt-4 bg-red-600 hover:bg-gray-500 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                            >
                                Delete Teacher
                            </button>
                        </div>
                    </div>
                    {/* Delete button */}
                    {/* <div className="flex justify-center items-center w-full">
                        <button
                            onClick={handleDeleteModalToggle}
                            className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mt-4"
                        >
                            Delete the Teacher
                        </button>
                    </div> */}
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            {/* ... (delete confirmation modal content remains the same) ... */}
                            <div className="p-4 md:p-5 text-center">
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this Teacher?
                                </h3>
                                
                                <button
                                    onClick={handleDeleteModalToggle}
                                    disabled={isDeleteLoading}
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                >
                                    Cancel
                                </button>.
                                <button
                                    onClick={handleDeleteTeacher}
                                    disabled={isDeleteLoading}
                                    className="text-white bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-500 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                >
                                    {isDeleteLoading && <IconLoader className="animate-spin mr-2" />}
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TeacherDetails;