import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { createContext, useRef, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import './App.css';
import { NavBar } from './components/navigation';
import { OffcanvasComponent } from './components/offcanvas';
import About from './pages/About/about';
import AdminPage from './pages/Admin/AdminPage';
import Adminpanel from './pages/Admin/Adminpanel';
import Batch from './pages/Admin/batch/Batch';
import BatchEdit from './pages/Admin/batch/BatchEdit';
import BatchList from './pages/Admin/batch/BatchList';
import ClassEdit from './pages/Admin/class/ClassEdit';
import ClassSchedule from './pages/Admin/ClassSchedule';
import ClassView from './pages/Admin/class/ClassView';
import CoursePage from './pages/Admin/CourseAdd';
import CourseEdit from './pages/Admin/CourseEdit';
import CourseView from './pages/Admin/CourseList';
import Role from './pages/Admin/RoleAdd';
import RoleEdit from './pages/Admin/RoleEdit';
import RoleList from './pages/Admin/RoleList';
import StudentDetail from './pages/Admin/students/StudentDetail';
import StudentEdit from './pages/Admin/students/StudentEdit';
import StudentList from './pages/Admin/students/StudentList';
import TeacherAdd from './pages/Admin/teacher/TeacherAdd';
import TeacherView from './pages/Admin/teacher/TeacherList';
import TeacherEdit from './pages/Admin/teacher/TeacherEdit';
import Contact from './pages/contact/contact';
import { CoursesPage } from './pages/Courses/page';
import { GallaryPage } from './pages/gallary/page';
import { HomePage } from './pages/home/page';
import { LibraryMain } from './pages/library/library';
import PagenotFount from './pages/PagenotFount';
import DivisionList from './pages/Admin/division/DivisionList';
import { DivisionAdd } from './pages/Admin/division/DivisionAdd';
import { DivisionEdit } from './pages/Admin/division/DivisionEdit';

export const ADMIN_URL_PATH= 'admin12390'
gsap.registerPlugin(ScrollTrigger)
export const GlobelContext = createContext();


function App() {
  const scrollBarRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('home');
  const [rebuildlocations, setRebuildlocations] = useState(false);
  const adminRoute = ADMIN_URL_PATH;

  const setSelectedtab = (tab) => {
    setSelectedTab(tab);
  };

  const rebuildLocationfn = () => {
    setRebuildlocations(!rebuildlocations);
  };

  return (
    <div className=' font-libre' ref={scrollBarRef} id='scrollableBar'>
      <GlobelContext.Provider value={{
        scrollBarRef,
        selectedTab: selectedTab,
        setSelectedtab,
        rebuildLocationfn,
        rebuildlocations
      }}>
        <BrowserRouter>


          <Routes>
            <Route path={`/${adminRoute}/*`}  >
              <Route index element={<AdminPage />} />
              {/* other pages */}
              <Route path='*' element={<OffcanvasComponent />}>
                <Route path='adminpanel' element={<StudentList />} />
                <Route path='student_edit/:id' element={<StudentEdit />} />
                <Route path='add_students' element={<Adminpanel />} />
                <Route path='course_add' element={<CoursePage />} />
                <Route path='course' element={<CourseView />} />
                <Route path='course_edit/:id' element={<CourseEdit />} />
                <Route path='role_add' element={< Role />} />
                <Route path='role_edit/:id' element={< RoleEdit />} />
                <Route path='role' element={< RoleList />} />
                <Route path='teacher' element={< TeacherView />} />
                <Route path='teacher_add' element={< TeacherAdd />} />
                <Route path='teacher_edit/:id' element={<TeacherEdit />} />
                <Route path='division' element={< DivisionList />} />
                <Route path='division_add' element={< DivisionAdd />} />
                <Route path='division_edit/:id' element={<DivisionEdit />} />
                <Route path='batch_add' element={< Batch />} />
                <Route path='batch' element={< BatchList />} />
                <Route path='batch_edit/:id' element={<BatchEdit />} />
                <Route path='class' element={<ClassView />} />
                <Route path='class_add' element={<ClassSchedule />} />
                <Route path='class_edit/:id' element={<ClassEdit />} />
                <Route path='student_detail/:id' element={<StudentDetail />} />
                <Route path='teacher_detail/:id' element={<CourseView />} />
                <Route path='*' element={<PagenotFount />} />
              </Route>
            </Route>
            <Route path='/*' element={<AppLayout />} />
          </Routes>

        </BrowserRouter>
      </GlobelContext.Provider>
    </div>
  );
}

function AppLayout() {
  const location = useLocation();





  return (
    <>
      <NavBar />

      <Routes>
        <Route index element={<Navigate to={'/home'} replace />} />
        <Route path='/home' element={<HomePage />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/about' element={<About />} />
        <Route path='/library' element={<LibraryMain />} />
        <Route path='/Courses' element={<CoursesPage />} />
        <Route path='/gallery' element={<GallaryPage />} />
        {/* <Route path='/design' element={<DesignPage />} /> */}
        {/* <Route path='404' element={<PagenotFount />} /> */}
        <Route path='*' element={<PagenotFount />} />


      </Routes>

      {/* <Footer /> */}
    </>
  );
}

export default App;
