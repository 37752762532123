import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../components/adminAxios';
import { ADMIN_URL_PATH } from '../../App';
import { IconArrowLeft } from '@tabler/icons-react';
import Select from 'react-select'; // Import React-Select

function ClassSchedule() {
  const navigate = useNavigate();

  const [batchOptions, setBatchOptions] = useState([]); // Updated to store batch options with both name and id
  const [divisionMap, setDivisionMap] = useState({});
  const [divisionNames, setDivisionNames] = useState([]);
  const [formData, setFormData] = useState({
    M03_SubjectName: '',
    M03_Title: '',
    M03_ScheduledAt: '',
    M03_ExpiryTime: '',
    M03_M02_BatchId: '', // Store batch ID directly here
    M03_M11_DivisionIds: [], // Division IDs stored as an array
    M03_YouTubeLink: ''
  });
  const [errors, setErrors] = useState({});

  // Fetch batches and store them as options
  const fetchId = async () => {
    try {
      const response = await api.get("https://elearningbackend.prosevo.com/api/batch_ids");
      const batchData = response.data.data;

      // Convert batch data into options with value (id) and label (name)
      const batchOptions = Object.entries(batchData).map(([name, id]) => ({
        label: name,
        value: id
      }));
      setBatchOptions(batchOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDivisions = async (batchId) => {
    try {
      const response = await api.get(`https://elearningbackend.prosevo.com/api/division?batch_id=${batchId}`);
      const divisions = response.data.data.data;
      if (divisions.length > 0) {
        const divisionMap = divisions.reduce((map, division) => {
          map[division.M11_Division_name] = division.M11_Division_id;
          return map;
        }, {});
        setDivisionMap(divisionMap);
        setDivisionNames(divisions.map(division => ({
          value: division.M11_Division_id, // ID to send to the backend
          label: division.M11_Division_name // Division name to display
        })));
      } else {
        setDivisionMap({});
        setDivisionNames([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchId();
  }, []);

  const extractYouTubeId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the batch ID directly using the value (ID)
    if (name === "M03_M02_BatchId") {
      setFormData({
        ...formData,
        M03_M02_BatchId: value, // Set batch ID directly
        M03_M11_DivisionIds: [] // Reset the division when batch changes
      });

      // Fetch divisions based on the selected batch ID
      if (value) {
        fetchDivisions(value);
      } else {
        setDivisionMap({});
        setDivisionNames([]);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }

    setErrors({
      ...errors,
      [name]: ''
    });
  };

  // Handle multiple division selection change
  const handleDivisionChange = (selectedOptions) => {
    const divisionIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData({
      ...formData,
      M03_M11_DivisionIds: divisionIds
    });
  };

  const handleonSubmit = async (processedFormData) => {
    try {
      const response = await api.post(
        "https://elearningbackend.prosevo.com/api/classes",
        processedFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navigate(`/${ADMIN_URL_PATH}/class`);
      console.log(response, '----------its the response');
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!formData.M03_SubjectName.trim()) {
      formErrors.M03_SubjectName = 'Subject is required';
    }
    if (!formData.M03_Title.trim()) {
      formErrors.M03_Title = 'Title is required';
    }
    if (!formData.M03_ScheduledAt.trim()) {
      formErrors.M03_ScheduledAt = 'Schedule is required';
    }
    if (!formData.M03_ExpiryTime.trim()) {
      formErrors.M03_ExpiryTime = 'Expire is required';
    }
    if (!formData.M03_M02_BatchId) {
      formErrors.M03_M02_BatchId = 'Batch ID is required';
    }
    if (formData.M03_M11_DivisionIds.length === 0) {
      formErrors.M03_M11_DivisionIds = 'At least one division is required';
    }
    if (!formData.M03_YouTubeLink.trim()) {
      formErrors.M03_YouTubeLink = 'YouTube Link is required';
    }

    if (Object.keys(formErrors).length === 0) {
      const youtubeId = extractYouTubeId(formData.M03_YouTubeLink);
      const processedFormData = {
        ...formData,
        M03_YouTubeLink: youtubeId || formData.M03_YouTubeLink
      };
      handleonSubmit(processedFormData);
    } else {
      setErrors(formErrors);
    }
  };


  return (
    <div className="bg-[#eba21a1f] min-h-screen flex items-center justify-center p-4 relative">
      <div className="w-full absolute left-5 top-5">
        <Link
          to={`/${ADMIN_URL_PATH}/class`}
          className="block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"
        >
          <IconArrowLeft />
        </Link>
      </div>
      <div className="w-full max-w-4xl bg-freen-100 p-8 rounded-lg">
        <h1 className="text-3xl font-semibold text-black mb-8">Class Schedule</h1>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Subject</label>
              <input
                type="text"
                name="M03_SubjectName"
                placeholder='Physics'
                value={formData.M03_SubjectName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
              />
              {errors.M03_SubjectName && <span className="text-red-600">{errors.M03_SubjectName}</span>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Title</label>
              <input
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                type="text"
                name="M03_Title"
                placeholder='Module 1'
                value={formData.M03_Title}
                onChange={handleChange}
              />
              {errors.M03_Title && <span className="text-red-600">{errors.M03_Title}</span>}
            </div>

             <div>
              <label className="block text-sm font-medium text-gray-700">Batch</label>
              <select
                name="M03_M02_BatchId"
                value={formData.M03_M02_BatchId} // Directly bind to batch ID
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
              >
                <option value="">Select Batch</option>
                {batchOptions.map((batch) => (
                  <option key={batch.value} value={batch.value}> {/* Use ID as value */}
                    {batch.label} {/* Display name */}
                  </option>
                ))}
              </select>
              {errors.M03_M02_BatchId && <span className="text-red-600">{errors.M03_M02_BatchId}</span>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Division</label>
              <Select
                isMulti // Enable multiple selection
                name="M03_M11_DivisionIds" // Updated key name here
                options={divisionNames}
                value={divisionNames.filter(option => formData.M03_M11_DivisionIds.includes(option.value))} // Updated key name here
                onChange={handleDivisionChange}
                className="mt-1"
                isDisabled={!divisionNames.length} // Disable if no divisions available
              />
              {errors.M03_M11_DivisionIds && <span className="text-red-600">{errors.M03_M11_DivisionIds}</span>} {/* Updated key name here */}
            </div>

            
            <div>
              <label className="block text-sm font-medium text-gray-700">Schedule Time</label>
              <input
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                type="datetime-local"
                name="M03_ScheduledAt"
                value={formData.M03_ScheduledAt}
                onChange={handleChange}
              />
              {errors.M03_ScheduledAt && <span className="text-red-600">{errors.M03_ScheduledAt}</span>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Expire Time</label>
              <input
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                type="datetime-local"
                name="M03_ExpiryTime"
                value={formData.M03_ExpiryTime}
                onChange={handleChange}
              />
              {errors.M03_ExpiryTime && <span className="text-red-600">{errors.M03_ExpiryTime}</span>}
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">YouTube Link</label>
              <input
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                type="url"
                name="M03_YouTubeLink"
                value={formData.M03_YouTubeLink}
                onChange={handleChange}
                placeholder="Enter full YouTube URL"
              />
              {errors.M03_YouTubeLink && <span className="text-red-600">{errors.M03_YouTubeLink}</span>}
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className="w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pumpkinToast-500 hover:bg-pumpkinToast-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pumpkinToast-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ClassSchedule;