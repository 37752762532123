import React, { useEffect, useState } from 'react';
import api from '../../../components/adminAxios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { IconArrowLeft } from '@tabler/icons-react';
import { ADMIN_URL_PATH } from '../../../App';

function ClassEdit() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [batchOptions, setBatchOptions] = useState([]);
    const [getDiv, setGetDiv] = useState([]); // Holds initially selected divisions
    const [divisionNames, setDivisionNames] = useState([]); // Holds available divisions
    const [formData, setFormData] = useState({
        M03_SubjectName: '',
        M03_Title: '',
        M03_ScheduledAt: '',
        M03_ExpiryTime: '',
        M03_M02_BatchId: '',
        M03_M11_DivisionIds: [], // Division IDs array
        M03_YouTubeLink: ''
    });
    const [errors, setErrors] = useState({});

    // Fetch batch options
    const fetchBatches = async () => {
        try {
            const response = await api.get("/batch_ids");
            const batchData = response.data.data;
            const options = Object.entries(batchData).map(([name, id]) => ({
                label: name,
                value: id
            }));
            setBatchOptions(options);
        } catch (error) {
            console.error("Error fetching batch IDs:", error);
        }
    };

    // Fetch divisions for selected batch
    const fetchDivisions = async (batchId) => {
        try {
            const response = await api.get(`https://elearningbackend.prosevo.com/api/division?batch_id=${batchId}`);
            const divisions = response.data.data.data;
            const options = divisions.map(division => ({
                value: division.M11_Division_id,
                label: division.M11_Division_name
            }));
            setDivisionNames(options); // Set available divisions
        } catch (error) {
            console.error("Error fetching divisions:", error);
        }
    };

    // Fetch class data and populate the form
    const fetchClass = async () => {
        try {
            const response = await api.get(`/classes/${id}`);
            const classData = response.data.data;

            // Pre-select divisions from the class data
            const selectedDivisions = classData.divisions.map(division => ({
                value: division.M11_Division_id,
                label: division.M11_Division_name
            }));
            setGetDiv(selectedDivisions); // Set initially selected divisions

            // Populate form fields
            setFormData({
                M03_SubjectName: classData.M03_SubjectName || '',
                M03_Title: classData.M03_Title || '',
                M03_ScheduledAt: classData.M03_ScheduledAt || '',
                M03_ExpiryTime: classData.M03_ExpiryTime || '',
                M03_M02_BatchId: classData.M03_M02_BatchId || '',
                M03_M11_DivisionIds: selectedDivisions.map(division => division.value),
                M03_YouTubeLink: classData.M03_YouTubeLink || ''
            });

            // Fetch divisions for the selected batch
            if (classData.M03_M02_BatchId) {
                fetchDivisions(classData.M03_M02_BatchId);
            }

        } catch (error) {
            console.error("Error fetching class:", error);
        }
    };

    useEffect(() => {
        fetchBatches();
        fetchClass();
    }, [id]);

    // Handle form changes (Batch and other fields)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
            ...(name === "M03_M02_BatchId" && { M03_M11_DivisionIds: [] }) // Reset divisions on batch change
        }));
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));

        if (name === "M03_M02_BatchId") {
            if (value) {
                fetchDivisions(value); // Fetch divisions for selected batch
            } else {
                setDivisionNames([]);
            }
        }
    };

    // Handle division selection changes
    const handleDivisionChange = (selectedOptions) => {
        const divisionIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData(prevData => ({
            ...prevData,
            M03_M11_DivisionIds: divisionIds
        }));
    };

    // Validate form
    const validateForm = () => {
        const requiredFields = ['M03_SubjectName', 'M03_Title', 'M03_ScheduledAt', 'M03_M02_BatchId', 'M03_M11_DivisionIds', 'M03_YouTubeLink'];
        return requiredFields.reduce((acc, field) => {
            if (!formData[field] || (field === 'M03_M11_DivisionIds' && formData.M03_M11_DivisionIds.length === 0)) {
                acc[field] = `${field.replace('M03_', '').replace('_', ' ')} is required`;
            }
            return acc;
        }, {});
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            try {
                await api.put(`/classes/${id}`, formData);
                navigate(`/admin/class`);
            } catch (error) {
                console.error("Error updating class:", error);
            }
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <div className="bg-[#eba21a1f] min-h-screen flex items-center justify-center p-4 relative">
            <div className="w-full absolute left-5 top-5">
                <Link to={`/${ADMIN_URL_PATH}/class`} className="block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm">
                    <IconArrowLeft />
                </Link>
            </div>
            <div className="w-full max-w-4xl p-8 rounded-lg">
                <h1 className="text-3xl font-semibold text-black mb-8">Edit Class</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Subject</label>
                            <input
                                type="text"
                                name="M03_SubjectName"
                                placeholder='Physics'
                                value={formData.M03_SubjectName}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                            />
                            {errors.M03_SubjectName && <span className="text-red-600">{errors.M03_SubjectName}</span>}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Title</label>
                            <input
                                type="text"
                                name="M03_Title"
                                placeholder='Module 1'
                                value={formData.M03_Title}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                            />
                            {errors.M03_Title && <span className="text-red-600">{errors.M03_Title}</span>}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Batch</label>
                            <select
                                name="M03_M02_BatchId"
                                value={formData.M03_M02_BatchId}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                            >
                                <option value="">Select a batch</option>
                                {batchOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            {errors.M03_M02_BatchId && <span className="text-red-600">{errors.M03_M02_BatchId}</span>}
                        </div>
                        <div>
                            <label>Division</label>
                            <Select
                                isMulti
                                value={divisionNames.filter(option => formData.M03_M11_DivisionIds.includes(option.value))}
                                options={divisionNames}
                                onChange={handleDivisionChange}
                                className="mt-1"
                                classNamePrefix="select"
                            />
                            {errors.M03_M11_DivisionIds && <span>{errors.M03_M11_DivisionIds}</span>}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Scheduled At</label>
                            <input
                                type="datetime-local"
                                name="M03_ScheduledAt"
                                value={formData.M03_ScheduledAt}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                            />
                            {errors.M03_ScheduledAt && <span className="text-red-600">{errors.M03_ScheduledAt}</span>}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Expiry Time</label>
                            <input
                                type="datetime-local"
                                name="M03_ExpiryTime"
                                value={formData.M03_ExpiryTime}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                            />
                            {errors.M03_ExpiryTime && <span className="text-red-600">{errors.M03_ExpiryTime}</span>}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">YouTube Link</label>
                            <input
                                type="text"
                                name="M03_YouTubeLink"
                                placeholder='https://www.youtube.com/watch?v=xxxxx'
                                value={formData.M03_YouTubeLink}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pumpkinToast-500 focus:ring-pumpkinToast-500"
                            />
                            {errors.M03_YouTubeLink && <span className="text-red-600">{errors.M03_YouTubeLink}</span>}
                        </div>
                    </div>
                    <div className="flex justify-end mt-6 w-full">
                        <button
                            type="submit"
                            className="px-4 w-full py-2 bg-pumpkinToast-500 text-white rounded-md hover:bg-pumpkinToast-600 focus:outline-none focus:ring-2 focus:ring-pumpkinToast-500 focus:ring-opacity-50"
                        >
                            Update Class
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ClassEdit;
