import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLoader, IconPlus, IconChevronLeft, IconChevronRight, IconSearch } from '@tabler/icons-react';
import api from '../../../components/adminAxios';
import TeacherDetails from './TeacherDetails';
import FilterButton from '../students/filterbutton';
import { debounce } from 'lodash';
import { ADMIN_URL_PATH } from '../../../App';

function TeacherList() {
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [batches, setBatches] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
  });

  const fetchTeachers = useCallback(async (page = 1, search = '', batchId = '') => {
    setIsLoading(true);
    const token = localStorage.getItem('adminToken');
    try {
      if (!token) {
        throw new Error('NO TOKEN FOUND IN LOCAL STORAGE');
      }

      let url = `/teachers?page=${page}&search=${search}`;
      if (batchId) {
        url += `&batch_id=${batchId}`;
      }

      const response = await api.get(url);

      setTeachers(response.data.data.data);
      setPagination({
        total: response.data.data.total,
        per_page: response.data.data.per_page,
        current_page: response.data.data.current_page,
        last_page: response.data.data.last_page,
      });
    } catch (error) {
      console.error('Error loading teachers:', error);
      alert(error.response?.data?.message || 'Error loading data');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedFetchTeachers = useCallback(
    debounce((search, batchId) => fetchTeachers(1, search, batchId), 1000),
    [fetchTeachers]
  );

  useEffect(() => {
    debouncedFetchTeachers(searchTerm, selectedBatchId);
  }, [debouncedFetchTeachers, searchTerm, selectedBatchId]);

  const fetchBatches = useCallback(async () => {
    const token = localStorage.getItem('adminToken');
    try {
      if (!token) {
        throw new Error("NO TOKEN FOUND IN LOCAL STORAGE");
      }

      const response = await api.get('/batches');
      setBatches(response.data.data.data);
    } catch (error) {
      console.error('Error loading batches:', error);
    }
  }, []);

  useEffect(() => {
    fetchBatches();
  }, [fetchBatches]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTeacherClick = (id) => {
    setSelectedTeacherId(id);
    setIsModalOpen(true);
  };

  const handleFilterChange = (filterId, value) => {
    if (filterId === 'batch') {
      setSelectedBatchId(value);
    }
  };

  const handleTeacherDelete = useCallback((deletedTeacherId) => {
    setTeachers((prevTeachers) =>
      prevTeachers.filter((teacher) => teacher.M05_UserId !== deletedTeacherId)
    );
    setIsModalOpen(false);
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.last_page) {
      fetchTeachers(newPage, searchTerm, selectedBatchId);
    }
  };

  const filters = [
    {
      id: 'batch',
      label: 'Batch',
      value: selectedBatchId,
      options: batches.map(batch => ({ value: batch.M02_BatchId, label: batch.M02_Name })),
      placeholder: 'All Batches',
    },
  ];

  return (
    <div className="min-h-screen p-6">
      <div className="flex justify-between flex-col-reverse sm:flex-row gap-5 mb-3 sm:mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder="Search teachers..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="pl-10 pr-4 w-full sm:w-auto py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pumpkinToast-500"
          />
          <IconSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        <div className='flex sm:justify-normal justify-between sm:gap-5 gap-3'>   
          <button
            onClick={() => navigate(`/${ADMIN_URL_PATH}/teacher_add`)}
            className="btn md:text-base text-sm bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white px-4 py-2 rounded flex items-center"
          >
            <IconPlus className="me-2" />
            ADD TEACHER
          </button>
          <FilterButton filters={filters} onFilterChange={handleFilterChange} />
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <div className="max-h-[calc(100vh-220px)] overflow-y-auto">
            <table className="w-full text-sm text-left">
              <thead className="text-xs text-white uppercase bg-pumpkinToast-400 sticky top-0">
                <tr>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">#</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Teacher name</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Country</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Gender</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Date of Birth</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Phone Number</th>
                  <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Email</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-center">
                      <IconLoader className="animate-spin mx-auto text-pumpkinToast-500" />
                    </td>
                  </tr>
                ) : teachers.length > 0 ? (
                  teachers.map((teacher, index) => (
                    <tr
                      key={teacher.M05_UserId}
                      className="bg-[#fbcf9c54] border-b border-[#f8c78036] text-black cursor-pointer hover:bg-[#fbcf9c8c] transition duration-150 ease-in-out"
                      onClick={() => handleTeacherClick(teacher.M05_UserId)}
                    >
                      <td className="px-6 py-2 md:py-4 font-medium text-nowrap">
                                                {(pagination.current_page - 1) * pagination.per_page + index + 1}
                                            </td>
                      <td className="px-6 py-2 md:py-4 font-medium text-nowrap">{teacher.M05_UserName}</td>
                      <td className="px-6 py-2 md:py-4 text-nowrap">{teacher.M05_CountryName}</td>
                      <td className="px-6 py-2 md:py-4 text-nowrap">{teacher.M05_Gender}</td>
                      <td className="px-6 py-2 md:py-4 text-nowrap">{teacher.M05_DOB}</td>
                      <td className="px-6 py-2 md:py-4 text-nowrap">{teacher.M05_MobileNumber}</td>
                      <td className="px-6 py-2 md:py-4 text-nowrap">{teacher.M05_Email}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-center font-bold text-red-600">
                      NO TEACHERS FOUND
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Pagination */}
      {pagination && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => handlePageChange(pagination.current_page - 1)}
            disabled={pagination.current_page === 1}
            className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-l-md disabled:bg-gray-300"
          >
            <IconChevronLeft size={20} />
          </button>
          <span className="px-4 py-2 bg-white">
            Page {pagination.current_page} of {pagination.last_page}
          </span>
          <button
            onClick={() => handlePageChange(pagination.current_page + 1)}
            disabled={pagination.current_page === pagination.last_page}
            className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-r-md disabled:bg-gray-300"
          >
            <IconChevronRight size={20} />
          </button>
        </div>
      )}

      {isModalOpen && (
        <TeacherDetails
          id={selectedTeacherId}
          onClose={() => setIsModalOpen(false)}
          onDelete={handleTeacherDelete}
        />
      )}
    </div>
  );
}

export default TeacherList;