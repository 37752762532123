import React, { useState, useRef, useEffect } from 'react';
import { IconFilter } from '@tabler/icons-react';

const FilterButton = ({ filters, onFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFilterChange = (filterId, value) => {
    onFilterChange(filterId, value);
  };

  return (
    <div className="relative" ref={filterRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="btn md:text-base sm:text-sm text-xs bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white px-2 sm:px-4 py-2 rounded flex items-center"
      >
        <IconFilter className="sm:me-2 me-1" />
        FILTER
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
          <div className="p-2">
            {filters.map((filter) => (
              <div key={filter.id} className="mb-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {filter.label}
                </label>
                <select
                  value={filter.value}
                  onChange={(e) => handleFilterChange(filter.id, e.target.value)}
                  disabled={filter.disabled}
                  className="w-full p-2 border rounded"
                >
                  <option value="">{filter.placeholder || `All ${filter.label}`}</option>
                  {filter.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterButton;