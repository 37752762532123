import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import api from "../../../components/adminAxios";
import { Link, useNavigate } from "react-router-dom";
import { IconArrowLeft, IconLoader, IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { ADMIN_URL_PATH } from "../../../App";

export function DivisionAdd() {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [batches, setBatches] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const mentorsPerPage = 10;

  useEffect(() => {
    fetchTeachers();
    fetchBatches();
  }, []);

  const fetchTeachers = async () => {
    try {
      const response = await api.get(
        "https://elearningbackend.prosevo.com/api/teachers"
      );
      const filteredTeachers = response.data.data.data.map((teacher) => ({
        id: teacher.M05_UserId,
        name: teacher.M05_UserName,
      }));
      setMentors(filteredTeachers);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  const fetchBatches = async () => {
    try {
      const response = await api.get("batch_ids");
      const batchData = response.data.data;
      const formattedBatches = Object.entries(batchData).map(([label, value]) => ({
        value: value,
        label: label,
      }));
      setBatches(formattedBatches);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("M11_Division_name", data.M11_Division_name);
    formData.append("M11_Batch_id", data.M11_Batch_id);
    formData.append("M11_M05_User_Id", data.M11_M05_User_Id);

    try {
      const response = await api.post("/division", formData);
      navigate(`/${ADMIN_URL_PATH}/division`);
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      if (error.response && error.response.status === 422 && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        Object.keys(validationErrors).forEach((field) => {
          setError(field, {
            type: "server",
            message: validationErrors[field].join(", "),
          });
        });
      } else {
        console.error("Error submitting form:", error);
        alert(error.response.data.errors || "Error adding form");
      }
    } finally {
      setLoading(false);
    }
  };

  // Pagination Logic
  const totalPages = Math.ceil(mentors.length / mentorsPerPage);
  const currentMentors = mentors.slice(
    (currentPage - 1) * mentorsPerPage,
    currentPage * mentorsPerPage
  );

  return (
    <div>
      <div className="bg-[#eba21a1f] w-full min-h-screen flex justify-center items-center p-8 pt-0 overflow-auto relative">
        <div className="w-full absolute left-5 top-5">
          <Link
            to={`/${ADMIN_URL_PATH}/division`}
            className="block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"
          >
            <IconArrowLeft />
          </Link>
        </div>
        <div className="w-full max-w-4xl ">
          <h1 className="text-3xl font-semibold text-black mb-10">Division Creation</h1>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid md:grid-cols-2 gap-4"
          >
            {/* Division Name */}
            <Controller
              name="M11_Division_name"
              control={control}
              rules={{ required: "Division Name is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M11_Division_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Division Name
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M11_Division_name"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="Division A"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* Batch Select */}
            <Controller
              name="M11_Batch_id"
              control={control}
              rules={{ required: "Batch ID is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M11_Batch_id"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Batch
                  </label>
                  <select
                    {...field}
                    disabled={loading}
                    id="M11_Batch_id"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    <option value="">Select Batch</option>
                    {batches.map((batch) => (
                      <option key={batch.value} value={batch.value}>
                        {batch.label}
                      </option>
                    ))}
                  </select>
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* Mentor Select */}
            <Controller
              name="M11_M05_User_Id"
              control={control}
              rules={{ required: "Mentor is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M11_M05_User_Id"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mentor
                  </label>
                  <select
                    {...field}
                    disabled={loading}
                    id="M11_M05_User_Id"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    <option value="">Select Mentor</option>
                    {mentors.map((mentor) => (
                      <option key={mentor.id} value={mentor.id}>
                        {mentor.name}
                      </option>
                    ))}
                  </select>
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* Submit Button */}
            <div className="col-span-full">
              <button
                disabled={loading}
                type="submit"
                className="w-full py-2 px-4 flex gap-2 justify-center bg-pumpkinToast-500 text-white rounded-md shadow-sm hover:bg-pumpkinToast-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                {loading && <IconLoader className="animate-spin" />}
                Submit
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
}
