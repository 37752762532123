import React, { useEffect, useState } from "react";
import api from "../../../components/adminAxios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { IconArrowLeft, IconArrowBack, IconLoader } from "@tabler/icons-react"; // Import IconArrowBack for the reset button
import { ADMIN_URL_PATH } from "../../../App";

function TeacherEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [batches, setBatches] = useState([]);
  const [roles, setRoles] = useState([]);
  const [initialPassword, setInitialPassword] = useState(""); // State to store the initial password
  const [studentRoleId, setStudentRoleId] = useState(null);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      M05_UserName: "",
      M05_UserCode: "",
      M05_CountryName: "",
      M05_DOB: "",
      M05_Gender: "",
      M05_Qualification: "",
      M05_Address: "",
      M05_MobileNumber: "",
      M05_Email: "",
      M05_JoiningDate: "",
      M05_AllowLogin: false,
      M05_IsActive: false,
      M05_M04_Role: "", // Single role select
      M05_Password: "", // Password field
    },
  });

  useEffect(() => {
    fetchRoles();
    getStudent();
    fetchBatches();
  }, []);


  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: "1px solid #dee2e6",
  //     borderColor: state.isFocused
  //       ? "#2684FF"
  //       : errors[state.selectProps.name]
  //         ? "#ff000080" // Red border for errors
  //         : "#cccccc",
  //     "&:hover": {
  //       borderColor: state.isFocused
  //         ? "#2684FF"
  //         : errors[state.selectProps.name]
  //           ? "#ff000080" // Red border for errors
  //           : "#cccccc",
  //     },
  //     boxShadow: errors[state.selectProps.name]
  //       ? "0 0 0 1px red"
  //       : provided.boxShadow,
  //   }),
  // };

  const fetchBatches = async () => {
    try {
      const response = await api.get('batch_ids');
      const batchData = response.data.data;
      const formattedBatches = Object.entries(batchData).map(([label, value]) => ({
        value: value,
        label: label
      }));
      setBatches(formattedBatches);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const getStudent = async () => {
    try {
      const response = await api.get(`/teachers/${id}`);
      // console.log(response, 'response of getStudent');
      const studentData = response.data.data;
      const fetchedPassword = studentData?.M05_Password || "I*Ra>?v2E]be";
      setInitialPassword(studentData.M05_Password);
      reset({
        M05_UserName: studentData.M05_UserName || '',
        M05_UserCode: studentData.M05_UserCode || '',
        M05_CountryName: studentData.M05_CountryName || '',
        M05_DOB: studentData.M05_DOB || '',
        M05_Gender: studentData.M05_Gender || '',
        M05_Qualification: studentData.M05_Qualification || '',
        M05_Address: studentData.M05_Address || '',
        M05_MobileNumber: studentData.M05_MobileNumber || '',
        M05_Email: studentData.M05_Email || '',
        M05_JoiningDate: studentData.M05_JoiningDate || '',
        // roles: studentData.roles || [],
        M05_AllowLogin: studentData.M05_AllowLogin || false,
        M05_IsActive: studentData.M05_IsActive || false,
        M05_M02_BachId: studentData?.batch?.M02_BatchId,
        M06_Specialization: studentData.teacher?.M06_Specialization,
        M05_Password: fetchedPassword,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await api.get("https://elearningbackend.prosevo.com/api/new_role");
      const filteredRoles = response.data.data.map((role) => ({
        value: role.M04_RoleId,
        label: role.M04_RoleName,
      }));

      setRoles(filteredRoles);

      // Automatically set the "teacher" role
      const teacherRole = filteredRoles.find((role) => role.label === "teacher");
      if (teacherRole) {
        setStudentRoleId(teacherRole.value);
        setValue("M05_M04_Role", teacherRole.value); // Set the value in form
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setValue("M05_Password", password); // Set the generated password in form
    return password;
  };

  const handleResetPassword = () => {
    setValue("M05_Password", initialPassword); // Reset to the initially fetched password
  };


  const onSubmit = async (formData) => {
    setLoading(true);
    try {
      const updatedData = {
        ...formData,
        // Set M05_M04_Role[] in the required format
        "M05_M04_Role[]": formData.M05_M04_Role, // Rename the key to "M05_M04_Role[]"
      };
  
      // Remove M05_M04_Role from the data
      delete updatedData.M05_M04_Role;
  
      const response = await api.put(`/users/${id}`, updatedData);
      navigate(`/${ADMIN_URL_PATH}/teacher`);
    } catch (error) {
      if (error.response && error.response.status === 422 && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        Object.keys(validationErrors).forEach((field) => {
          setError(field, {
            type: "server",
            message: validationErrors[field].join(", "),
          });
        });
      } else {
        console.error("Error submitting form:", error);
        alert(error.response.data.errors || "Error Updating teacher");
      }
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="bg-[#eba21a1f] w-full min-h-screen flex justify-center items-center p-8 overflow-auto relative">
      <div className=" w-ful absolute left-5 top-5">
        <Link to={`/${ADMIN_URL_PATH}/teacher`} className=" block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"><IconArrowLeft /></Link>

      </div>

      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-semibold text-black mb-10">
          Edit Teacher Detail's
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
        >
          <Controller
            name="M05_UserName"
            control={control}
            rules={{ required: "User Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_UserName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Teacher Name
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_UserName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Teacher name"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_UserCode"
            control={control}
            rules={{ required: "User Code is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_UserCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Teacher Code
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_UserCode"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="##001"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_CountryName"
            control={control}
            rules={{ required: "Country Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_CountryName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country Name
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_CountryName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="India"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Gender"
            control={control}
            rules={{ required: "Gender is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Gender
                </label>
                <select
                  {...field}
                  id="M05_Gender"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_DOB"
            control={control}
            rules={{ required: "Date of Birth is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_DOB"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date of Birth
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="date"
                  id="M05_DOB"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_M02_BachId"
            control={control}
            rules={{ required: "Batch ID is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_M02_BachId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Batch ID
                </label>
                <select
                  {...field}
                  id="M05_M02_BachId"
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 ${error ? "border-red-600" : ""}`}
                >
                  <option value="">Select Batch</option>
                  {batches.map((batch) => (
                    <option key={batch.value} value={batch.value}>
                      {batch.label}
                    </option>
                  ))}
                </select>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_M04_Role"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label htmlFor="M05_M04_Role" className="block text-sm font-medium text-gray-700 mb-1">
                  Role
                </label>
                <select
                  {...field}
                  id="M05_M04_Role"
                  className={`block w-full mt-1 rounded-md border-gray-300 shadow-sm sm:text-sm ${error ? "border-red-500" : ""}`}
                  disabled // Disable the dropdown to prevent changes
                >
                  {roles.map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
                {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
              </div>
            )}
          />

          <Controller
            name="M05_Qualification"
            control={control}
            rules={{ required: "Qualification is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Qualification"
                  className="block text-sm font-medium text-gray-700"
                >
                  Qualification
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_Qualification"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Plus Two"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M06_Specialization"
            control={control}
            rules={{ required: "Specialization is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M06_Specialization"
                  className="block text-sm font-medium text-gray-700"
                >
                  Specialization
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M06_Specialization"
                  placeholder="specialize"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_JoiningDate"
            control={control}
            rules={{ required: "Joining Date is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_JoiningDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Joining Date
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="date"
                  id="M05_JoiningDate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_MobileNumber"
            control={control}
            rules={{
              required: "Mobile Number is required",
              pattern: {
                value: /^\+?[1-9]\d{1,14}$/,
                message: "Invalid phone number",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_MobileNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobile Number
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="tel"
                  id="M05_MobileNumber"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="+91 1234567890"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Address"

            control={control}
            rules={{ required: "Address is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-4">
                <label
                  htmlFor="M05_Address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address
                </label>
                <textarea
                  {...field}
                  disabled={loading}
                  id="M05_Address"
                  rows="1"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                ></textarea>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="email"
                  id="M05_Email"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="teacher@gmail.com"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div className="col-span-2 mb-4 sm:mb-0">
                <label htmlFor="M05_Password" className="block text-sm font-medium text-gray-700">
                  App Password
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    {...field}
                    readOnly
                    type="text"
                    id="M05_Password"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="Auto-generated password"
                  />
                  <button
                    type="button"
                    onClick={() => field.onChange(generatePassword())}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md"
                  >
                    Generate
                  </button>
                  <button
                    type="button"
                    onClick={handleResetPassword}
                    className="px-4 py-2 bg-gray-300 text-black rounded-md"
                  >
                    <IconArrowBack stroke={2} />
                  </button>
                </div>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />



          {/* <Controller
            name="M05_AllowLogin"
            control={control}
            render={({ field }) => (
              <div className="flex items-center mb-4 sm:mb-0">
                <input
                  {...field}
                  disabled={loading}
                  type="checkbox"
                  id="M05_AllowLogin"
                  className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                />
                <label
                  htmlFor="M05_AllowLogin"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Allow Login
                </label>
              </div>
            )}
          /> */}

          {/* <Controller
            name="M05_IsActive"
            control={control}
            render={({ field }) => (
              <div className="flex items-center mb-4 sm:mb-0">
                <input
                  {...field}
                  disabled={loading}
                  type="checkbox"
                  id="M05_IsActive"
                  className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                />
                <label
                  htmlFor="M05_IsActive"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Is Active
                </label>
              </div>
            )}
          /> */}

          <div className="col-span-full">
            <button
              disabled={loading}
              type="submit"
              className="w-full py-2 px-4 flex gap-2 justify-center bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {loading && <IconLoader className="animate-spin" />}
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TeacherEdit