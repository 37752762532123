import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLoader, IconEdit, IconTrash } from '@tabler/icons-react';
import api from '../../../components/adminAxios';
import { ADMIN_URL_PATH } from '../../../App';

function DivisionDetails({ id, onClose, onDelete }) {
    const navigate = useNavigate();
    const [division, setDivision] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    useEffect(() => {
        const fetchDivisionDetails = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/division/${id}`);
                setDivision(response.data.data);
            } catch (error) {
                console.error('Error fetching division details:', error);
                alert(error.response?.data?.message || 'Error loading division details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchDivisionDetails();
    }, [id]);

    const handleDeleteModalToggle = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleDeleteDivision = async () => {
        setIsDeleteLoading(true);
        try {
            const response = await api.delete(`/division/${id}`);
            if (response.data.success) {
                onDelete(id);
                onClose();
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error('Error deleting division:', error);
            alert(error.response?.data?.message || 'Error deleting division');
        } finally {
            setIsDeleteLoading(false);
        }
    };

    const handleEdit = () => {
        navigate(`/${ADMIN_URL_PATH}/division_edit/${id}`);
    };

    if (isLoading) {
        return (
            <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
                <IconLoader className="animate-spin text-black" size={48} />
            </div>
        );
    }

    return (
        <div className="fixed inset-0 z-50 flex justify-center items-center overflow-y-auto bg-gray-800 bg-opacity-50">
            <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700 p-4 max-w-2xl w-full m-4">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Division Details
                    </h3>
                    <button
                        onClick={onClose}
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">
                            {division.M11_Division_name}'s Details
                        </h2>
                        <button onClick={handleEdit} className="text-gray-600 hover:text-gray-900">
                            <IconEdit size={24} />
                        </button>
                    </div>
                    <div className="space-y-4">
                        <div className="flex justify-between items-center border-b pb-2">
                            <p className="font-medium text-gray-600">Division Name:</p>
                            <p className="text-gray-900">{division?.M11_Division_name}</p>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <p className="font-medium text-gray-600">Batch:</p>
                            <p className="text-gray-900">{division.batch.M02_Name}</p>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <p className="font-medium text-gray-600">Mentor Name:</p>
                            <p className="text-gray-900">{division.mentor?.M05_UserName}</p>
                        </div>
                    </div>
                    <div className="mt-6 flex justify-center">
                        <button
                            onClick={handleDeleteModalToggle}
                            className="flex items-center justify-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                        >
                            {/* <IconTrash className="mr-2" size={20} /> */}
                            Delete Division
                        </button>
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 max-w-sm w-full">
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Confirm Deletion</h3>
                        <p className="text-gray-700 mb-6">Are you sure you want to delete this division?</p>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={handleDeleteModalToggle}
                                disabled={isDeleteLoading}
                                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition duration-300"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDeleteDivision}
                                disabled={isDeleteLoading}
                                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300 flex items-center"
                            >
                                {isDeleteLoading && <IconLoader className="animate-spin mr-2" size={20} />}
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DivisionDetails;