import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLoader, IconPlus, IconChevronLeft, IconChevronRight, IconSearch, IconDownload } from '@tabler/icons-react';
import api from '../../../components/adminAxios';
import StudentDetail from './StudentDetail';
import FilterButton from './filterbutton';
import { debounce } from 'lodash';
import { ADMIN_URL_PATH } from '../../../App';

function StudentList() {
    const navigate = useNavigate();
    const [students, setStudents] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [batches, setBatches] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [selectedBatchId, setSelectedBatchId] = useState('');
    const [selectedDivisionId, setSelectedDivisionId] = useState('');
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
    });

    const fetchStudents = useCallback(async (page = 1, search = '', batchId = '', divisionId = '') => {
        setIsLoading(true);
        const token = localStorage.getItem('adminToken');
        try {
            if (!token) {
                throw new Error('NO TOKEN FOUND IN LOCAL STORAGE');
            }

            let url = `/students?page=${page}&search=${search}`;
            if (batchId) {
                url += `&batch_id=${batchId}`;
            }
            if (divisionId) {
                url += `&division_id=${divisionId}`;
            }

            const response = await api.get(url);

            setStudents(response.data.data.data);
            setPagination({
                total: response.data.data.total,
                per_page: response.data.data.per_page,
                current_page: response.data.data.current_page,
                last_page: response.data.data.last_page,
            });
        } catch (error) {
            console.error('Error loading students:', error);
            alert(error.response?.data?.message || 'Error loading data');
        } finally {
            setIsLoading(false);
        }
    }, []);

    const debouncedFetchStudents = useCallback(
        debounce((search, batchId, divisionId) => fetchStudents(1, search, batchId, divisionId), 1000),
        [fetchStudents]
    );

    useEffect(() => {
        debouncedFetchStudents(searchTerm, selectedBatchId, selectedDivisionId);
    }, [debouncedFetchStudents, searchTerm, selectedBatchId, selectedDivisionId]);

    const fetchBatches = useCallback(async () => {
        const token = localStorage.getItem('adminToken');
        try {
            if (!token) {
                throw new Error("NO TOKEN FOUND IN LOCAL STORAGE");
            }

            const response = await api.get('/batches');
            setBatches(response.data.data.data);
        } catch (error) {
            console.error('Error loading batches:', error);
        }
    }, []);

    const fetchDivisions = useCallback(async (batchId) => {
        if (!batchId) {
            setDivisions([]);
            return;
        }

        const token = localStorage.getItem('adminToken');
        try {
            if (!token) {
                throw new Error("NO TOKEN FOUND IN LOCAL STORAGE");
            }

            const response = await api.get(`/division?batch_id=${batchId}`);
            setDivisions(response.data.data.data);
        } catch (error) {
            console.error('Error loading divisions:', error);
        }
    }, []);

    useEffect(() => {
        fetchBatches();
    }, [fetchBatches]);

    useEffect(() => {
        if (selectedBatchId) {
            fetchDivisions(selectedBatchId);
        } else {
            setDivisions([]);
            setSelectedDivisionId('');
        }
    }, [selectedBatchId, fetchDivisions]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleStudentClick = (id) => {
        setSelectedStudentId(id);
        setIsModalOpen(true);
    };

    const handleFilterChange = (filterId, value) => {
        if (filterId === 'batch') {
            setSelectedBatchId(value);
            setSelectedDivisionId('');
        } else if (filterId === 'division') {
            setSelectedDivisionId(value);
        }
    };

    const handleStudentDelete = useCallback((deletedStudentId) => {
        setStudents((prevStudents) =>
            prevStudents.filter((student) => student.M05_UserId !== deletedStudentId)
        );
        setIsModalOpen(false);
    }, []);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.last_page) {
            fetchStudents(newPage, searchTerm, selectedBatchId, selectedDivisionId);
        }
    };

    // Trigger download with filters
    const handleDownload = async () => {
        let url = `/students?export=export`;
    
        // Append batch and division filters if they are selected
        if (selectedBatchId) {
            url += `&batch_id=${selectedBatchId}`;
        }
        if (selectedDivisionId) {
            url += `&division_id=${selectedDivisionId}`;
        }
    
        const token = localStorage.getItem('adminToken');
        
        try {
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'blob', // Important for handling binary data like Excel files
            });
    
            // Create a Blob from the response data and create a download link
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
    
            // Create a download link and click it programmatically
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
    
            // Set a default filename (you can customize it)
            link.download = 'students_export.xlsx';
    
            // Append the link to the body, click it to trigger download, and then remove the link
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('Error downloading the file.');
        }
    };
    

    const filters = [
        {
            id: 'batch',
            label: 'Batch',
            value: selectedBatchId,
            options: batches.map(batch => ({ value: batch.M02_BatchId, label: batch.M02_Name })),
            placeholder: 'All Batches',
        },
        {
            id: 'division',
            label: 'Division',
            value: selectedDivisionId,
            options: divisions.map(division => ({ value: division.M11_Division_id, label: division.M11_Division_name })),
            disabled: !selectedBatchId,
            placeholder: 'All Divisions',
        },
    ];

    return (
        <div className="min-h-screen p-6">
            <div className="flex justify-between flex-col-reverse sm:flex-row gap-5 mb-3 sm:mb-6">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search students..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="pl-10 pr-4 w-full sm:w-auto py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pumpkinToast-500"
                    />
                    <IconSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
                <div className='flex sm:justify-normal justify-between sm:gap-5 gap-3'>
                    <button
                        className='btn md:text-base text-sm bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white px-2 sm:px-4 py-2 rounded flex items-center'
                        onClick={handleDownload}
                    >
                        Download <IconDownload className='ms-2' stroke={2} />
                    </button>
                    <button
                        onClick={() => navigate(`/${ADMIN_URL_PATH}/add_students`)}
                        className="btn md:text-base text-sm bg-pumpkinToast-500 hover:bg-pumpkinToast-400 text-white px-2 py-2 sm:px-4 rounded flex items-center"
                    >
                        <IconPlus className="me-2" />
                        ADD <span className='sm:block hidden'>Student</span>
                    </button>
                    <FilterButton filters={filters} onFilterChange={handleFilterChange} />
                </div>
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <div className="max-h-[calc(100vh-220px)] overflow-y-auto">
                        <table className="w-full text-sm text-left">
                            <thead className="text-xs text-white uppercase bg-pumpkinToast-400 sticky top-0">
                                <tr>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">#</th>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Student name</th>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Country</th>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Gender</th>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Date of Birth</th>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Phone Number</th>
                                    <th className="sm:px-6 px-3 py-3 text-nowrap text-xs sm:textsm md:text-base">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan="6" className="px-6 py-4 text-center">
                                            <IconLoader className="animate-spin mx-auto text-pumpkinToast-500" />
                                        </td>
                                    </tr>
                                ) : students.length > 0 ? (
                                    students.map((student, index) => (
                                        <tr
                                            key={student.M05_UserId}
                                            className="bg-[#fbcf9c54] border-b border-[#f8c78036] text-black cursor-pointer hover:bg-[#fbcf9c8c] transition duration-150 ease-in-out"
                                            onClick={() => handleStudentClick(student.M05_UserId)}
                                        >
                                            <td className="px-6 py-2 md:py-4 font-medium text-nowrap">
                                                {(pagination.current_page - 1) * pagination.per_page + index + 1}
                                            </td>
                                            <td className="px-6 py-2 md:py-4 font-medium text-nowrap">{student.M05_UserName}</td>
                                            <td className="px-6 py-2 md:py-4 text-nowrap">{student.M05_CountryName}</td>
                                            <td className="px-6 py-2 md:py-4 text-nowrap">{student.M05_Gender}</td>
                                            <td className="px-6 py-2 md:py-4 text-nowrap">{student.M05_DOB}</td>
                                            <td className="px-6 py-2 md:py-4 text-nowrap">{student.M05_MobileNumber}</td>
                                            <td className="px-6 py-2 md:py-4 text-nowrap">{student.M05_Email}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="px-6 py-4 text-center font-bold text-red-600">
                                            NO STUDENTS FOUND
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Pagination */}
            {pagination && (
                <div className="flex justify-center items-center mt-4">
                    <button
                        onClick={() => handlePageChange(pagination.current_page - 1)}
                        disabled={pagination.current_page === 1}
                        className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-l-md disabled:bg-gray-300"
                    >
                        <IconChevronLeft size={20} />
                    </button>
                    <span className="px-4 py-2 bg-white">
                        Page {pagination.current_page} of {pagination.last_page}
                    </span>
                    <button
                        onClick={() => handlePageChange(pagination.current_page + 1)}
                        disabled={pagination.current_page === pagination.last_page}
                        className="px-4 py-2 bg-pumpkinToast-500 text-white rounded-r-md disabled:bg-gray-300"
                    >
                        <IconChevronRight size={20} />
                    </button>
                </div>
            )}

            {isModalOpen && (
                <StudentDetail
                    id={selectedStudentId}
                    onClose={() => setIsModalOpen(false)}
                    onDelete={handleStudentDelete}
                />
            )}
        </div>
    );
}

export default StudentList;